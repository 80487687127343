export const setLandingPage = () => {
  let landingPage = sessionStorage.getItem('landingPage')
  if (landingPage) {
    return sessionStorage.removeItem('isLandingPage')
  }

  sessionStorage.setItem('isLandingPage', true)
  sessionStorage.setItem('landingPage', window.location.href)
}

export const setOriginURLSession = () => {
  let originURLSession = sessionStorage.getItem('originURLSession')
  if (!originURLSession) {
    let referrer = document.referrer
    if (!referrer.length) {
      referrer = 'There\'s no referrer'
    }
    sessionStorage.setItem('originURLSession', referrer)
  }
}
