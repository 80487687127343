import { maskJs } from 'mask-js'

const masks = [
  {
    type: 'phone',
    mask: '(99) 9999?9-9999'
  },
  {
    type: 'cep',
    mask: '99999-999'
  },
  {
    type: 'cpf',
    mask: '999.999.999-99'
  },
  {
    type: 'date',
    mask: '99/99/9999'
  }
]

export const inputMask = (type, value) => {
  const maskType = masks.find(mask => mask.type === type)

  if (!maskType) {
    return value
  }

  return maskJs(maskType.mask, value)
}
