import React from 'react'
import PropTypes from 'prop-types'
import Ink from 'react-ink'

const Button = ({
  type,
  children,
  className,
  isDisabled,
  onClickHandler
}) => (
  <button
    type={type}
    className={className}
    onClick={onClickHandler}
    disabled={isDisabled}
  >
    {children}

    {!isDisabled && <Ink />}
  </button>
)

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClickEvent: PropTypes.func
}

Button.defaultProps = {
  type: 'button',
  children: '',
  className: '',
  isDisabled: false,
  onClickHandler: () => {
  }
}

export default Button
