import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  fetchInvoiceEmail: ['email'],
  fetchInvoiceZipCode: ['zipCode'],
  fetchInvoiceNeighborhood: ['neighborhood'],
  fetchInvoiceCity: ['city'],
  fetchInvoiceState: ['state'],
  fetchInvoiceStreet: ['street'],
  fetchInvoiceNumber: ['number'],
  fetchInvoiceComplement: ['complement'],
  fetchElectronicInvoicing: ['electronicInvoicing'],
  fetchInvoiceAddress: ['invoiceAddress']
})

const INITIAL_STATE = {
  email: '',
  zipCode: '',
  neighborhood: '',
  city: '',
  state: '',
  invoiceAddress: false,
  street: '',
  number: '',
  complement: '',
  electronicInvoicing: true
}

const getInvoiceEmail = (state = INITIAL_STATE, action) => ({
  ...state,
  email: action.email
})

const getInvoiceZipCode = (state = INITIAL_STATE, action) => ({
  ...state,
  zipCode: action.zipCode
})

const getInvoiceNeighborhood = (state = INITIAL_STATE, action) => ({
  ...state,
  neighborhood: action.neighborhood
})

const getInvoiceCity = (state = INITIAL_STATE, action) => ({
  ...state,
  city: action.city
})

const getInvoiceState = (state = INITIAL_STATE, action) => ({
  ...state,
  state: action.state
})

const getInvoiceStreet = (state = INITIAL_STATE, action) => ({
  ...state,
  street: action.street
})

const getInvoiceNumber = (state = INITIAL_STATE, action) => ({
  ...state,
  number: action.number
})

const getInvoiceComplement = (state = INITIAL_STATE, action) => ({
  ...state,
  complement: action.complement
})

const getElectronicInvoicing = (state = INITIAL_STATE, action) => ({
  ...state,
  electronicInvoicing: action.electronicInvoicing
})

const getInvoiceAddress = (state = INITIAL_STATE, action) => ({
  ...state,
  invoiceAddress: action.invoiceAddress
})

export const HANDLERS = {
  [Types.FETCH_INVOICE_EMAIL]: getInvoiceEmail,
  [Types.FETCH_INVOICE_ZIP_CODE]: getInvoiceZipCode,
  [Types.FETCH_INVOICE_NEIGHBORHOOD]: getInvoiceNeighborhood,
  [Types.FETCH_INVOICE_CITY]: getInvoiceCity,
  [Types.FETCH_INVOICE_STATE]: getInvoiceState,
  [Types.FETCH_INVOICE_STREET]: getInvoiceStreet,
  [Types.FETCH_INVOICE_NUMBER]: getInvoiceNumber,
  [Types.FETCH_INVOICE_COMPLEMENT]: getInvoiceComplement,
  [Types.FETCH_ELECTRONIC_INVOICING]: getElectronicInvoicing,
  [Types.FETCH_INVOICE_ADDRESS]: getInvoiceAddress
}

export default createReducer(INITIAL_STATE, HANDLERS)
