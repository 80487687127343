import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import reducers from './ducks'
import history from '../routes/history'

import { getExpirationControlState, saveState } from '../utils/localStorage'

let persistedState = getExpirationControlState()

const middlewares = [thunk, routerMiddleware(history)]

/* eslint-disable no-underscore-dangle */
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
/* eslint-enable */

const store = createStore(
  reducers(history),
  persistedState,
  composeEnhancer(applyMiddleware(...middlewares))
)

store.subscribe(() => {
  saveState('shoppingCart', {
    cart: store.getState().cart,
    user: store.getState().user,
    address: store.getState().address,
    products: store.getState().products,
    router: store.getState().router
  })
})

export default store
