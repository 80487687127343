import axios from 'axios'
import gql from 'graphql-tag'
import { print } from 'graphql'

const VENDAS_GRAPHQL_API = process.env.REACT_APP_VENDAS_GRAPHQL_API ||
  'https://dev-api-vendas.conecta.zelas.com.br/graphql'
const CATALOG_GRAPHQL_API = process.env.REACT_APP_CATALOG_GRAPHQL_API ||
  'https://dev-api.conecta.zelas.com.br/graphql'
const CONTACT_API = process.env.REACT_APP_CONTACT_API ||
  'https://dev.origin-contact.zelasconecta.com.br'
const WIRED_ZIPCODE_API = process.env.REACT_APP_WIRED_ZIPCODE_API ||
  'https://api.escaleos.escale.com.br/telecom-base-cep/availability'
const ZIPCODE_INFO_API = process.env.REACT_APP_ZIPCODE_INFO_API ||
  'https://ajbjum9xkj.execute-api.us-east-1.amazonaws.com/prod/zipcode/correios'

const requestConfig = {
  baseURL: 'https://api.mir-catalog.escale.com.br/',
  method: 'get'
}

const instance = axios.create(requestConfig)

const getProducsByCity = async (city, uf, kind) => {
  const getProducts = gql`
  query($city: String, $state: String, $kind: String) {
    getProducts(city: $city, state: $state, provider: "net", kind: $kind) {
      id
      productId
      customName
      city
      name
      kind
      extraAdhesionNoDirectDebit
      extraPriceNoDirectDebitElectronicInvoice
      extraPriceNoElectronicInvoice
      extraPriceNoDirectDebit
      extra
      providerId
      price
      fullPrice
      internet {
        name
        providerInternetId
        price
        extraAdhesionNoDirectDebit
        extraPriceNoDirectDebitElectronicInvoice
        extraPriceNoElectronicInvoice
        extraPriceNoDirectDebit
        extra
      }
      tv {
        name
        providerTvId
        price
        extraAdhesionNoDirectDebit
        extraPriceNoDirectDebitElectronicInvoice
        extraPriceNoElectronicInvoice
        extraPriceNoDirectDebit
        extra
        channels
      }
      phone {
        name
        providerPhoneId
        price
        extraAdhesionNoDirectDebit
        extraPriceNoDirectDebitElectronicInvoice
        extraPriceNoElectronicInvoice
        extraPriceNoDirectDebit
        extra
      }
    }
  }
`
  const response = await axios.post(CATALOG_GRAPHQL_API, {
    query: print(getProducts),
    variables: { city: city, state: uf, kind: kind }
  })
  return response.data.data.getProducts
}

const getProductsById = async (id, city, uf, kind) => {
  const getProduct = gql`
  query($city: String, $state: String, $kind: String, $id: String) {
    getProduct(providerId: $id, city: $city, state: $state, provider: "net", kind: $kind) {
      id
      productId
      customName
      city
      name
      extraAdhesionNoDirectDebit
      extraPriceNoDirectDebitElectronicInvoice
      extraPriceNoElectronicInvoice
      extraPriceNoDirectDebit
      extra
      providerId
      price
      internet {
        name
        providerInternetId
        price
        extraAdhesionNoDirectDebit
        extraPriceNoDirectDebitElectronicInvoice
        extraPriceNoElectronicInvoice
        extraPriceNoDirectDebit
        extra
      }
      tv {
        name
        providerTvId
        price
        extraAdhesionNoDirectDebit
        extraPriceNoDirectDebitElectronicInvoice
        extraPriceNoElectronicInvoice
        extraPriceNoDirectDebit
        extra
        channels
      }
      phone {
        name
        providerPhoneId
        price
        extraAdhesionNoDirectDebit
        extraPriceNoDirectDebitElectronicInvoice
        extraPriceNoElectronicInvoice
        extraPriceNoDirectDebit
        extra
      }
    }
  }
`
  const response = await axios.post(CATALOG_GRAPHQL_API, {
    query: print(getProduct),
    variables: { city: city, state: uf, kind: kind, id: id }
  })
  return response.data.data.getProduct
}

const getChannelsByCity = async city => {
  city = city || '4885'
  try {
    return await instance
      .get(`cities/${city}/channels`)
      .then(response => response.data)
  } catch (error) {
    console.log(error)
  }
}

const getCepWired = async cep => {
  try {
    return await axios.get(`${WIRED_ZIPCODE_API}?cep=${cep}&provider=net`)
  } catch (error) {
    console.log(error)
  }
}

const getCitiesList = async () => {
  try {
    return await instance.get(`cities`)
  } catch (error) {
    console.log(error)
  }
}

const getAddressData = async cep => {
  try {
    return await axios.get(`${ZIPCODE_INFO_API}/${cep}`)
  } catch (error) {
    console.log(error)
  }
}

const getDiscoveryByCity = async (city, cart) => {
  city = city || '4885'

  const accepteds = ['internet', 'tv', 'phone']
  const getParameters = Object.keys(cart).reduce((acc, cur) => {
    if (!accepteds.includes(cur) || !cart[cur]) {
      return acc
    }

    return `${acc}${cur}=${cart[cur]}&`
  }, '')

  const parameters = getParameters.slice(0, -1)

  try {
    const response = await instance.get(
      `cities/${city}/discovery?${parameters}`
    )
    return response.data
  } catch (error) {
    console.error(error)
  }
}

const createOrder = async order => {
  const orderMutation = gql`
    mutation($user: UserInput!, $order: OrderInput!, $tracking: TrackingInput!) {
      createOrder(user: $user, order: $order, tracking: $tracking)
    }
  `

  try {
    return await axios.post(VENDAS_GRAPHQL_API, {
      query: print(orderMutation),
      variables: order
    })
  } catch (error) {
    console.log(error)
  }
}

const saveContacts = async userData => {
  try {
    return await axios.post(`${CONTACT_API}/cart/save`, userData)
  } catch (error) {
    console.log(error)
  }
}

const api = {
  getProducsByCity,
  getProductsById,
  getDiscoveryByCity,
  getChannelsByCity,
  getCitiesList,
  getCepWired,
  getAddressData,
  createOrder,
  saveContacts
}

export default api
