import React from 'react'
import Channels from '../../components/Channels'
import './ChannelGrid.scss'

const ChannelGrid = ({ channels }) => {
  return (
    <section className='channelGrid'>
      <Channels channels={channels} />
    </section>
  )
}

export default ChannelGrid
