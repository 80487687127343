import React, { Fragment } from 'react'
import uuidv1 from 'uuid/v1'
import { checkOptionalResources, convertPriceToString, getItemValue } from '../../utils/productHandlers'
import { Check, Input } from '../Inputs'
import { MinusButton, PlusButton } from '../Button/IconButtons'

export const OptionalTechnologies = ({
  discovery,
  cart,
  optionalsHandler
}) => {
  const { optionalTechnologies } = discovery
  return (
    <Fragment>
      {optionalTechnologies.length > 0 && (
        <h4 className='message__product__title'>Tecnologias Adicionais</h4>
      )}

      {optionalTechnologies.map(technology => (
        <div className='message__product__item' key={uuidv1()}>
          <span className='message__product__item--group'>
            <Check
              id={technology.optional_technology_id}
              title={technology.product_technology_name}
              onChange={optionalsHandler}
              checked={checkOptionalResources(technology.optional_technology_id, cart)}
            />

            <span className='message__product__price'>
              R$ {convertPriceToString(technology.price)}
            </span>
          </span>
        </div>
      ))
      }
    </Fragment>
  )
}

export const OptionalProducts = ({
  discovery,
  cart,
  optionalsHandler
}) => {
  const { optionalProducts } = discovery

  return (
    <Fragment>
      {optionalProducts.length > 0 && (
        <h4 className='message__product__title'>Produtos Adicionais</h4>
      )}

      {optionalProducts.map(product => (
        <div className='message__product__item' key={uuidv1()}>
          <span className='message__product__item--group'>
            <Check
              id={product.optional_product_id}
              title={product.name}
              onChange={optionalsHandler}
              checked={checkOptionalResources(product.optional_product_id, cart)}
            />

            <span className='message__product__price'>
              R$ {convertPriceToString(product.price)}
            </span>
          </span>
        </div>
      ))
      }
    </Fragment>
  )
}

export const OptionalSpots = ({
  discovery,
  cart,
  optionalsHandler,
  addQuantityHandler,
  removeQuantityHandler
}) => {
  const { optionalSpots } = discovery

  return (
    <Fragment>
      {optionalSpots.length > 0 && (
        <h4 className='message__product__title'>Pontos Adicionais</h4>
      )}

      {optionalSpots.map(spot => (
        <div className='message__product__item' key={uuidv1()}>
          <span className='message__product__item--group'>
            <Check
              id={spot.spot_id}
              onChange={optionalsHandler}
              checked={checkOptionalResources(spot.spot_id, cart)}
              label={spot.technology}
            />
            <span className='message__product__price'>
              R$ {convertPriceToString(spot.monthly_price)}
            </span>
          </span>

          <span className='message__product__item--group'>
            <span className='message__product__label'>Quantidade</span>
            <span className='message__product__controls'>
              <MinusButton
                className='message__product__button'
                onClickHandler={event => removeQuantityHandler(event, spot.spot_id)}
              />
              <Input
                type='number'
                onChange={optionalsHandler}
                value={getItemValue(spot.spot_id, cart)}
              />
              <PlusButton
                className='message__product__button'
                onClickHandler={event => addQuantityHandler(event, spot.spot_id)}
              />
            </span>
          </span>
        </div>
      ))
      }
    </Fragment>
  )
}
