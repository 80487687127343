import { string, object } from 'yup'

export default object().shape({
  paymentType: string(),
  dueDate: string().required('Campo obrigatório.'),
  bank: string()
    .when('paymentType', {
      is: 'debito',
      then: string().required('Campo obrigatório.'),
      otherwise: string().notRequired()
    }),
  agency: string()
    .when('paymentType', {
      is: 'debito',
      then: string().required('Aqui, você precisa incluir o número da agência.'),
      otherwise: string().notRequired()
    }),
  account: string()
    .when('paymentType', {
      is: 'debito',
      then: string().required('Aqui, você precisa incluir o número da sua conta.'),
      otherwise: string().notRequired()
    }),
  digit: string()
    .when('paymentType', {
      is: 'debito',
      then: string().required('Aqui, você precisa incluir o dígito da sua conta.'),
      otherwise: string().notRequired()
    })
})
