import React from 'react'
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { FaCheck } from 'react-icons/fa'

import './Inputs.scss'

export const Input = ({ label, error, ...props }) =>
  <fieldset className='input'>
    <label className='input__label' htmlFor={props.name}>
      {label}
    </label>
    <input {...props} className={`input__text ${error && 'error'}`} />
    {error && <span className='input__error-msg'>{error}</span>}
  </fieldset>

Input.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  props: PropTypes.object
}

export const Select = ({ label, error, children, ...props }) =>
  <fieldset name={props.name} className={`input ${props.disabled ? 'disabled' : ''}`}>
    <label className='input__label' htmlFor='key'>{label}</label>
    <div className='input__selectWrapper'>
      <select
        className={`input__select ${error && 'error'}`}
        {...props}
      >
        {children}
      </select>
    </div>
    {error && <span className='input__error-msg'>{error}</span>}
  </fieldset>

Select.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.array,
  props: PropTypes.object
}

export const Check = ({ label, error, ...props }) =>
  <fieldset
    className={`checkBox 
      ${props.type === 'radio' ? 'round' : ''} 
      ${props.checked ? 'checkBox--isChecked' : ''} `}
  >
    <label className='checkBox__label'>
      <input className='checkBox__input'
        {...props}
      />
      <div className='checkBox__marker'>
        <FaCheck />
      </div>
      <span className='checkBox__text'>{label}</span>
    </label>
    {error && <span className='input__error-msg'>{error}</span>}
  </fieldset>

Check.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  props: PropTypes.object
}

export const DateInput = ({ label, error, ...props }) =>
  <fieldset className='input'>
    <label className='input__label' htmlFor={props.name}>{label}</label>
    <DayPickerInput
      {...props}
    />
    {error && <span className='input__error-msg'>{error}</span>}
  </fieldset>

DateInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  props: PropTypes.object
}
