import React from 'react'
import PropTypes from 'prop-types'
import { FaAngleDown } from 'react-icons/fa'
import Ink from 'react-ink'
import Loading from '../Loading'
import { isZelasTheme } from '../../utils/getTheme'
import getTotalPrice from '../Prices/getTotalPrice'

import './SeeDetails.scss'

const LoadingDetails = () =>
  <p className='seeDetails__loading'>
    Atualizando preço <Loading />
  </p>

const SeeDetails = ({
  isDetailsCartActive,
  clickHandler,
  isLoading,
  cartProduct,
  directDebit,
  electronicInvoicing
}) => {
  return (
    <div className={`seeDetails ${isZelasTheme() && 'zelasSeeDetails'} ${isDetailsCartActive ? 'seeDetails--active' : ''}`}>
      <div className='seeDetails__item'>
        {isLoading
          ? <LoadingDetails />
          : <p>
            Total:
            <strong>
              R$ {getTotalPrice(cartProduct, directDebit, electronicInvoicing)}
            </strong>
          </p>}
      </div>

      <div
        className={`seeDetails__item seeDetails__item--right ${isZelasTheme() && 'zelasSeeDetails__item--right'}`}
        onClick={() => clickHandler()}
      >
        <p>
          <span className='seeDetails__icon'><FaAngleDown /></span>
          Ver detalhes
        </p>
        <Ink />
      </div>
    </div>
  )
}

SeeDetails.propTypes = {
  isDetailsCartActive: PropTypes.bool,
  clickHandler: PropTypes.func,
  product: PropTypes.object
}

SeeDetails.defaultProps = {
  isDetailsCartActive: false,
  clickHandler: () => {
  },
  product: {}
}

export default SeeDetails
