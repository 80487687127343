import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { addQuantityThunk, removeQuantityThunk, toggleOptionalsThunk } from '../../store/ducks/thunks'
import Message from '../../components/Message'
import CtaButton from '../../components/Button/CtaButton'

const OptionalFeatures = ({
  discovery,
  toggleOptionalsConnected,
  addQuantityConnected,
  removeQuantityConnected,
  cart,
  history
}) => (
  <Fragment>
    <Message
      optionalsHandler={toggleOptionalsConnected}
      addQuantityHandler={addQuantityConnected}
      removeQuantityHandler={removeQuantityConnected}
      discovery={discovery}
      cart={cart}
    />

    {history.location.pathname !== '/carrinho/' &&
    (<CtaButton onClickHandler={() => history.push('/carrinho/')} />)
    }
  </Fragment>
)

const mapStateToProps = store => ({
  loading: store.products.loading,
  discovery: store.cart.discovery,
  cart: store.cart
})

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleOptionalsConnected: toggleOptionalsThunk,
  addQuantityConnected: addQuantityThunk,
  removeQuantityConnected: removeQuantityThunk
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OptionalFeatures))
