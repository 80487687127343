import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  fetchPhone: ['phone'],
  fetchName: ['name'],
  fetchRg: ['rg'],
  fetchBirthday: ['birthday'],
  fetchCpf: ['cpf'],
  fetchEmail: ['email']
})

const INITIAL_STATE = {
  phone: '',
  name: '',
  rg: '',
  birthday: '',
  cpf: '',
  email: ''
}

const getPhone = (state = INITIAL_STATE, action) => ({
  ...state,
  phone: action.phone
})

const getName = (state = INITIAL_STATE, action) => ({
  ...state,
  name: action.name
})

const getRg = (state = INITIAL_STATE, action) => ({
  ...state,
  rg: action.rg
})

const getBirthday = (state = INITIAL_STATE, action) => ({
  ...state,
  birthday: action.birthday
})

const getEmail = (state = INITIAL_STATE, action) => ({
  ...state,
  email: action.email
})

const getCpf = (state = INITIAL_STATE, action) => ({
  ...state,
  cpf: action.cpf
})

export const HANDLERS = {
  [Types.FETCH_PHONE]: getPhone,
  [Types.FETCH_NAME]: getName,
  [Types.FETCH_RG]: getRg,
  [Types.FETCH_BIRTHDAY]: getBirthday,
  [Types.FETCH_EMAIL]: getEmail,
  [Types.FETCH_CPF]: getCpf
}

export default createReducer(INITIAL_STATE, HANDLERS)
