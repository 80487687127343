import React from 'react'
import PropTypes from 'prop-types'
import './ResumeCart.scss'

import Prices from '../../components/Prices'

import {
  convertPriceToString,
  convertStringToLowerCase,
  getComboProduct,
  getDiscoveryInfo
} from '../../utils/productHandlers'
import { isZelasTheme } from '../../utils/getTheme'
const prodTypes = ['internet', 'tv', 'phone']

const ResumeCart = ({
  discovery,
  cartOrders,
  cartProduct,
  directDebit,
  electronicInvoicing
}) => (
  <div className='resumeCart'>
    {prodTypes.map((type, index) => {
      const product = getComboProduct(cartProduct, type)
      if (!product.id) {
        return null
      }

      return (
        <div className='resumeCart__item' key={index}>
          <strong className='resumeCart__itemTitle'>
            <span className='resumeCart__itemTitle--uppercase'>
              {convertStringToLowerCase(`${type} ${product.name}`)}
            </span>
          </strong>

        </div>
      )
    })}
    <div className='resumeCart__total'>
      <strong className={`resumeCart__totalTitle ${isZelasTheme() && 'zelas__resumeCart__totalTitle'}`}>
        Total
      </strong>

      <div className='resumeCart__totalContent'>
        <Prices
          product={cartProduct}
          directDebit={directDebit}
          electronicInvoicing={electronicInvoicing}
        />
      </div>
    </div>

    {cartOrders.length > 0 && (
      <div className='resumeCart__info'>
        {getDiscoveryInfo(discovery).adhesionFee
          ? (
            <p className='resumeCart__infoContent'>
              Taxa de instalação por R$ {convertPriceToString(getDiscoveryInfo(discovery).adhesionFee)}.
            </p>
          ) : (
            <p className='resumeCart__infoContent'>
              Taxa de instalação grátis.
            </p>
          )
        }

        {getDiscoveryInfo(discovery).fineAmount
          ? (
            <p className='resumeCart__infoContent'>
              Valor da multa rescisória: R$ {convertPriceToString(getDiscoveryInfo(discovery).fineAmount)}.
            </p>
          ) : (
            <p className='resumeCart__infoContent'>
              Não há multa rescisória.
            </p>
          )
        }
      </div>
    )}
  </div>
)

ResumeCart.propTypes = {
  cartOrders: PropTypes.arrayOf(PropTypes.object),
  cartProduct: PropTypes.object,
  discovery: PropTypes.object,
  products: PropTypes.object,
  directDebit: PropTypes.bool.isRequired
}

ResumeCart.defaultProps = {
  cartOrders: [],
  cartProduct: {},
  discovery: {},
  products: {}
}

export default ResumeCart
