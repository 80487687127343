import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  fetchDueDate: ['dueDate'],
  fetchBank: ['bank'],
  fetchAgency: ['agency'],
  fetchAccount: ['account'],
  fetchDigit: ['digit'],
  fetchDirectDebit: ['directDebit']
})

const INITIAL_STATE = {
  dueDate: null,
  bank: {},
  agency: '',
  account: '',
  digit: '',
  directDebit: true
}

const getDueDateValue = (state = INITIAL_STATE, action) => ({
  ...state,
  dueDate: action.dueDate
})

const getBankValue = (state = INITIAL_STATE, action) => ({
  ...state,
  bank: action.bank
})

const getAgencyValue = (state = INITIAL_STATE, action) => ({
  ...state,
  agency: action.agency
})

const getAccountValue = (state = INITIAL_STATE, action) => ({
  ...state,
  account: action.account
})

const getDigitValue = (state = INITIAL_STATE, action) => ({
  ...state,
  digit: action.digit
})

const getDirectDebit = (state = INITIAL_STATE, action) => ({
  ...state,
  directDebit: action.directDebit
})

export const HANDLERS = {
  [Types.FETCH_DUE_DATE]: getDueDateValue,
  [Types.FETCH_BANK]: getBankValue,
  [Types.FETCH_AGENCY]: getAgencyValue,
  [Types.FETCH_ACCOUNT]: getAccountValue,
  [Types.FETCH_DIGIT]: getDigitValue,
  [Types.FETCH_DIRECT_DEBIT]: getDirectDebit
}

export default createReducer(INITIAL_STATE, HANDLERS)
