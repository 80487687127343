import { string, object, boolean } from 'yup'

export default object().shape({
  invoiceType: string(),
  invoiceAddress: boolean(),
  firstDate: string().required('Campo obrigatório.'),
  firstDatePeriod: string().required('Campo obrigatório.'),
  secondDate: string().required('Campo obrigatório.'),
  secondDatePeriod: string().required('Campo obrigatório.'),
  email: string().email('Esse e-mail não é válido. Tente incluir outro.')
    .when('invoiceType', {
      is: 'Por e-mail',
      then: string().required('Você precisa incluir um e-mail válido.'),
      otherwise: string().notRequired()
    }),
  zipCode: string()
    .when('invoiceAddress', {
      is: true,
      then: string().required('Insira um CEP válido para continuar.').length(9, 'Insira um CEP válido para continuar.'),
      otherwise: string().notRequired()
    }),
  neighborhood: string()
    .when('invoiceAddress', {
      is: true,
      then: string().required('Inclua seu bairro para continuar.'),
      otherwise: string().notRequired()
    }),
  city: string()
    .when('invoiceAddress', {
      is: true,
      then: string().required('Inclua sua cidade para continuar.'),
      otherwise: string().notRequired()
    }),
  state: string()
    .when('invoiceAddress', {
      is: true,
      then: string().required('Inclua seu estado para continuar.'),
      otherwise: string().notRequired()
    }),
  street: string()
    .when('invoiceAddress', {
      is: true,
      then: string().required('Inclua sua rua para continuar.'),
      otherwise: string().notRequired()
    }),
  number: string()
    .when('invoiceAddress', {
      is: true,
      then: string().required('Insira o número da sua residência aqui.'),
      otherwise: string().notRequired()
    })
})
