export const triggerEvent = (name, data) => {
  const event = new window.CustomEvent(name, {
    detail: data
  })

  return document.dispatchEvent(event)
}

const listenEvent = name => {
  return document.addEventListener(name, e => addOnDataLayer(name, e.detail || {}))
}

const addOnDataLayer = (event, content) => {
  let data = {}
  data['event'] = typeof event === 'string' ? event : ''

  data = Object.assign(data, content)

  return window.dataLayer ? window.dataLayer.push(data) : false
}

export const eventsToListen = () => {
  listenEvent('addProductToCart')
  listenEvent('removeProductFromCart')
  listenEvent('searchForWiring')
  listenEvent('openChannelGrid')
  listenEvent('doNotChooseProduct')
  listenEvent('createOrder')
}

export const addProductToCartEvent = (product = {}) =>
  triggerEvent('addProductToCart', {
    category: 'engagement',
    action: 'add product to cart',
    label: `${product.productId} - ${product.kind} ${product.name}`
  })

export const removeProductFromCartEvent = (prod = {}) => {
  return triggerEvent('removeProductFromCart', {
    category: 'engagement',
    action: 'remove product from cart',
    label: `${prod.product_id} - ${prod.product_type} ${prod.product_name}`
  })
}
