import React, { Fragment } from 'react'
import Message from '../../components/Message'
import { connect } from 'react-redux'
import { getBotTip, hasProductType } from '../../utils/botScript'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import './ThankYou.scss'

function ThankYou ({ analystName, avatarUrl, botTips }) {
  const successInstallation = getBotTip(botTips, 'successInstallation', null).text
  const successHelp = getBotTip(botTips, 'successHelp', null).text
  const checkSuccessInstallation = hasProductType(
    botTips,
    'successInstallation'
  )
  const checkSuccessHelp = hasProductType(botTips, 'successHelp')

  return (
    <Fragment>
      {successInstallation && !checkSuccessInstallation && (
        <Message
          name={analystName}
          avatarUrl={avatarUrl}
          message={RichText.render(successInstallation)}
          className='thank_you_message'
        />
      )}
      {successHelp && !checkSuccessHelp && (
        <Message
          name={analystName}
          avatarUrl={avatarUrl}
          message={RichText.render(successHelp)}
          className='thank_you_message'
        />
      )}
    </Fragment>
  )
}

ThankYou.propTypes = {
  analystName: PropTypes.string,
  avatarUrl: PropTypes.string,
  botTips: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = store => ({
  analystName: store.app.analyst.name,
  avatarUrl: store.app.analyst.avatarUrl,
  isLoading: store.app.isLoading,
  botTips: store.app.botTips
})

export default connect(mapStateToProps)(ThankYou)
