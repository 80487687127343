import React from 'react'
import { Link } from 'react-router-dom'
import Brand from '../Brand'
import './Header.scss'

export default () => (
  <header className='header zelas__header'>
    <div className='app__container'>
      <div className='header__content'>
        <Link to='/'>
          <Brand className='header__brand zelas__header__brand' />
        </Link>
      </div>
    </div>
  </header>
)
