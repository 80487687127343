import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  toggleDetailsCart: [''],
  toggleChannelGrid: [''],
  toggleChangeInternet: [''],
  toggleChangeTV: [''],
  toggleChangePhone: [''],
  toggleChangeOptionals: [''],
  toggleLoadingResources: [''],
  stopLoading: [''],
  fetchBotMessage: ['message'],
  fetchBotAnalyst: ['analyst'],
  setBotScripts: ['scripts'],
  setBotTips: ['tips'],
  setBotWarnings: ['warnings'],
  loadChannelGrid: ['channelGrid'],
  dismissDrawer: ['']
})

const INITIAL_STATE = {
  isDetailsCartActive: false,
  isChannelGridVisible: false,
  isLoading: false,
  isDrawerInternetVisible: false,
  isDrawerTvVisible: false,
  isDrawerPhoneVisible: false,
  isDrawerOptionalsVisible: false,
  analyst: {
    name: '',
    avatarUrl: ''
  },
  botMessage: '',
  botScripts: [],
  botTips: [],
  botWarnings: [],
  botTip: '',
  channelGrid: []
}

const toogleDetailsCart = (state = INITIAL_STATE) => ({
  ...state,
  isDetailsCartActive: !state.isDetailsCartActive
})

const toogleChannels = (state = INITIAL_STATE) => ({
  ...state,
  isChannelGridVisible: !state.isChannelGridVisible,
  isDrawerVisible: !state.isDrawerVisible
})

const toggleChangeInternet = (state = INITIAL_STATE) => ({
  ...state,
  isDrawerInternetVisible: !state.isDrawerInternetVisible,
  isDrawerVisible: !state.isDrawerVisible
})

const toggleChangeTV = (state = INITIAL_STATE) => ({
  ...state,
  isDrawerTvVisible: !state.isDrawerTvVisible,
  isDrawerVisible: !state.isDrawerVisible
})

const toggleChangePhone = (state = INITIAL_STATE) => ({
  ...state,
  isDrawerPhoneVisible: !state.isDrawerPhoneVisible,
  isDrawerVisible: !state.isDrawerVisible
})

const toggleChangeOptionals = (state = INITIAL_STATE) => ({
  ...state,
  isDrawerOptionalsVisible: !state.isDrawerOptionalsVisible,
  isDrawerVisible: !state.isDrawerVisible
})

const toggleLoadingApp = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: !state.isLoading
})

const stopLoading = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: false
})

const getBotMessage = (state = INITIAL_STATE, action) => ({
  ...state,
  botMessage: action.message
})

const getChannelGrid = (state = INITIAL_STATE, action) => ({
  ...state,
  channelGrid: action.channelGrid
})

const getBotAnalyst = (state = INITIAL_STATE, action) => ({
  ...state,
  analyst: { ...action.analyst }
})

const setScripts = (state = INITIAL_STATE, action) => ({
  ...state,
  botScripts: action.scripts
})

const setTips = (state = INITIAL_STATE, action) => ({
  ...state,
  botTips: action.tips
})

const setWarnings = (state = INITIAL_STATE, action) => ({
  ...state,
  botWarnings: action.warnings
})

const dismissDrawer = (state = INITIAL_STATE) => ({
  ...state,
  isDetailsCartActive: false,
  isChannelGridVisible: false,
  isDrawerVisible: false,
  isDrawerInternetVisible: false,
  isDrawerTvVisible: false,
  isDrawerPhoneVisible: false,
  isDrawerOptionalsVisible: false
})

export const HANDLERS = {
  [Types.TOGGLE_DETAILS_CART]: toogleDetailsCart,
  [Types.TOGGLE_CHANNEL_GRID]: toogleChannels,
  [Types.TOGGLE_CHANGE_INTERNET]: toggleChangeInternet,
  [Types.TOGGLE_CHANGE_TV]: toggleChangeTV,
  [Types.TOGGLE_CHANGE_PHONE]: toggleChangePhone,
  [Types.TOGGLE_CHANGE_OPTIONALS]: toggleChangeOptionals,
  [Types.LOAD_CHANNEL_GRID]: getChannelGrid,
  [Types.TOGGLE_LOADING_RESOURCES]: toggleLoadingApp,
  [Types.STOP_LOADING]: stopLoading,
  [Types.FETCH_BOT_MESSAGE]: getBotMessage,
  [Types.FETCH_BOT_ANALYST]: getBotAnalyst,
  [Types.DISMISS_DRAWER]: dismissDrawer,
  [Types.SET_BOT_SCRIPTS]: setScripts,
  [Types.SET_BOT_TIPS]: setTips,
  [Types.SET_BOT_WARNINGS]: setWarnings
}

export default createReducer(INITIAL_STATE, HANDLERS)
