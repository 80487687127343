/* eslint-disable comma-dangle */
import React from 'react'
import ReactDOM from 'react-dom'
import WebFont from 'webfontloader'
import { Provider } from 'react-redux'
import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import { isZelasTheme } from './utils/getTheme'
import { PrismicLink } from 'apollo-link-prismic'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloProvider } from 'react-apollo'

import './styles/main.scss'
import store from './store'

WebFont.load({
  google: {
    families: ['Montserrat:400,600,700', 'Open Sans:400,600,700', 'sans-serif']
  }
})

if (isZelasTheme()) {
  const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = 'zelas-favicon.ico'
  document.getElementsByTagName('head')[0].appendChild(link)
  document.title = 'Zelas Conecta | Os melhores planos em um só lugar'
}

const client = new ApolloClient({
  link: PrismicLink({
    uri: 'https://nikita-xd.prismic.io/graphql',
  }),
  cache: new InMemoryCache()
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Routes />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
