import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Header from '../Header'
import BotWithMessage from '../../components/BotWithMessage'
import HeaderNavigation from '../Header/HeaderNavigation'
import Drawer from '../Drawer'
import Details from '../Details'

const SharedContainers = ({
  children,
  history,
  pathname,
  name,
  avatarUrl,
  botMessage,
  isLoading
}) => (
  <Fragment>
    <Header />
    <div className='app__container'>
      {pathname !== '/' && <HeaderNavigation clickHandler={() => history.goBack()} />}

      {botMessage && (
        <BotWithMessage
          name={name}
          avatarUrl={avatarUrl}
          text={botMessage}
          isLoading={isLoading}
        />
      )}
      {children}
    </div>

    {pathname !== '/' && <Details />}
    <Drawer history={history} />
  </Fragment>
)

SharedContainers.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  avatarUrl: PropTypes.string,
  botMessage: PropTypes.string,
  pageDefaultsConnected: PropTypes.func
}

SharedContainers.defaultProps = {
  isLoading: false,
  name: '',
  avatarUrl: '',
  botMessage: ''
}

const mapStateToProps = store => ({
  pathname: store.router.location.pathname,
  locationKey: store.router.location.key,
  name: store.app.analyst.name,
  avatarUrl: store.app.analyst.avatarUrl,
  botMessage: store.app.botMessage,
  isLoading: store.app.isLoading
})

export default connect(mapStateToProps)(SharedContainers)
