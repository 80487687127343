import React, { Fragment } from 'react'
import Cookies from 'universal-cookie'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { string, object } from 'yup'

import {
  handleAddressThunk,
  handleCepThunk,
  handleNameThunk,
  handlePhoneThunk,
  handleUtmThunk,
  handleContactThunk } from '../../store/ducks/thunks'
import { Input } from '../../components/Inputs'
import CtaButton from '../../components/Button/CtaButton'
import { inputMask } from '../../utils/formMasks'
import FormHandler from '../../components/FormHandler'
import Error from '../../components/Error'

const Home = props => {
  const {
    name,
    phone,
    zipCode,
    handlePhoneConnected,
    handleCepConnected,
    handleNameConnected,
    handleAddressConnected,
    handleUtm,
    handleContact,
    history,
    location
  } = props

  const params = new URLSearchParams(location.search).get('name')
  const productInfo = params
    ? {
      name: params
    }
    : {}

  const validationSchema = object().shape({
    name: string().required('Inclua seu nome completo para continuar.'),
    phone: string().required('Inclua seu telefone para continuar.').min(14, 'Insira um telefone válido para continuar.'),
    zipCode: string().required('Insira um CEP válido para continuar.').length(9, 'Insira um CEP válido para continuar.')
  })

  const utm = {}
  const utmCookie = new Cookies().get('__utmz') || ''
  const cookieDict = 'utmcsr' + utmCookie.slice(utmCookie.indexOf('utmcsr') + 'utmcsr'.length)
  cookieDict.split('|').forEach(item => {
    const pairs = item.split('=')
    pairs[1] && (utm[pairs[0]] = pairs[1])
  })

  utm['landingPage'] = new URLSearchParams(location.search).get('landing_page') || null
  utm['originPage'] = document.location.origin + document.location.pathname || null
  utm['device'] = navigator.appVersion || null

  const initialValues = {
    name,
    phone: inputMask('phone', phone),
    zipCode: inputMask('cep', zipCode)
  }

  const handleSubmit = values => {
    handleCepConnected(values.zipCode)
    handlePhoneConnected(values.phone)
    handleNameConnected(values.name)
    handleAddressConnected(history, productInfo)
    handleUtm(utm)
    handleContact({
      name: values.name,
      phone: values.phone,
      cep: values.zipCode,
      product: productInfo.name || 'not informed',
      operator: 'net',
      utm_medium: utm.utmcmd || 'not informed',
      utm_source: utm.utmcsr || 'not informed',
      utm_campaign: utm.utmccn || 'not informed',
      device: utm.device || 'not informed',
      landing_page: utm.landingPage || 'not informed',
      origin_page: utm.originPage || 'not informed'
    })
  }

  return (
    <Fragment>
      <FormHandler
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <HomeForm {...props} />
      </FormHandler>
    </Fragment>
  )
}

const HomeForm = ({ values, touched, errors, handleChange, handleBlur, isLoading, haveError }) =>
  <Fragment>
    <Input
      type='name'
      name='name'
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.name}
      label='Nome'
      error={errors.name && touched.name ? errors.name : null}
    />

    <Input
      type='tel'
      name='phone'
      onChange={handleChange}
      onBlur={handleBlur}
      value={inputMask('phone', values.phone)}
      label='Telefone'
      placeholder='Ex.: (11) 9 9999 9999'
      error={errors.phone && touched.phone ? errors.phone : null}
    />

    <Input
      type='tel'
      name='zipCode'
      onChange={handleChange}
      onBlur={handleBlur}
      value={inputMask('cep', values.zipCode)}
      label='CEP'
      error={errors.zipCode && touched.zipCode ? errors.zipCode : null}
    />
    {haveError.error && <Error />}
    <CtaButton
      type='submit'
      isDisabled={isLoading || Object.keys(errors).length > 0}
      isLoading={isLoading}
    >
      Próximo passo
    </CtaButton>
  </Fragment>

const mapDispatchToProps = dispatch => bindActionCreators({
  handleCepConnected: handleCepThunk,
  handlePhoneConnected: handlePhoneThunk,
  handleNameConnected: handleNameThunk,
  handleAddressConnected: handleAddressThunk,
  handleUtm: handleUtmThunk,
  handleContact: handleContactThunk
}, dispatch)

const mapStateToProps = store => ({
  zipCode: store.address.zipCode,
  phone: store.user.phone,
  name: store.user.name,
  isLoading: store.app.isLoading,
  haveError: store.error
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
