import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Ink from 'react-ink'
import { FaCheck } from 'react-icons/fa'
import { RichText } from 'prismic-reactjs'
import {
  convertStringToLowerCase,
  pathnameToString
} from '../../utils/productHandlers'
import { getBotTip } from '../../utils/botScript'
import Message from '../Message'
import './ProductCard.scss'
import { isZelasTheme } from '../../utils/getTheme'
import Prices from '../Prices'

const ProductCard = props => {
  const {
    providerId,
    name,
    productType,
    productId,
    skipTitle,
    selectedPlan,
    cartHandler,
    channelsHandler,
    analyst,
    history,
    tips,
    productTips,
    tv
  } = props
  const product = history.location
    ? pathnameToString(history.location.pathname)
    : 'este produto'
  const skipPackage = getBotTip(tips, 'skipPackage', product)
  const noProduct = getBotTip(tips, 'noProducts', product)
  const discovery = getBotTip(tips, 'discovery', product, selectedPlan)
  return (
    <Fragment>
      {providerId === '0' && skipPackage.text && (
        <Message
          name={analyst.name}
          avatarUrl={analyst.avatarUrl}
          message={RichText.render(skipPackage.text)}
        />
      )}

      <div
        className={`productCard ${
          providerId === selectedPlan ? 'productCard--isActive' : ''
        }`}
        onClick={() => cartHandler(providerId)}
      >
        <div
          className={`productCard__header ${
            skipTitle ? 'productCard__header--skip' : ''
          } ${isZelasTheme() && 'zelas__productCard__header'}`}
        >
          {!skipTitle ? (
            <h3>{convertStringToLowerCase(`${productType} ${name}`)}</h3>
          ) : (
            <h3>{skipTitle}</h3>
          )}

          <div className='productCard__control'>
            <FaCheck />
          </div>
        </div>

        {!skipTitle && (
          <Fragment>
            <div className='productCard__content'>
              {productTips &&
                productTips.tip &&
                RichText.render(productTips.tip)}

              {productType === 'tv' && (
                <span
                  className={`productCard__details__link ${isZelasTheme() &&
                    'zelas__productCard__details__link'}`}
                  onClick={() => channelsHandler(tv.channels, name)}
                >
                  Ver grade de canais
                </span>
              )}
            </div>
            <div className='productCard__price'>
              <Prices
                product={{ ...props }}
                directDebit
                electronicInvoicing
              />
            </div>
          </Fragment>
        )}
        <Ink opacity={0.05} />
      </div>

      {providerId === selectedPlan && discovery.text && discovery.productid === productId && (
        <Message
          name={analyst.name}
          avatarUrl={analyst.avatarUrl}
          message={RichText.render(discovery.text)}
        />
      )}

      {!!skipTitle && selectedPlan === '0' && noProduct.text && (
        <Message
          name={analyst.name}
          avatarUrl={analyst.avatarUrl}
          message={RichText.render(noProduct.text)}
        />
      )}
    </Fragment>
  )
}

ProductCard.propTypes = {
  providerId: PropTypes.string,
  name: PropTypes.string,
  product_type: PropTypes.string,
  price: PropTypes.string,
  skipTitle: PropTypes.string,
  description: PropTypes.string,
  discovery: PropTypes.any,
  history: PropTypes.object,
  productTips: PropTypes.object
}

ProductCard.defaultProps = {
  providerId: '0',
  name: '',
  product_type: '',
  price: '0',
  skipTitle: '',
  description: '',
  discovery: {},
  history: {
    location: {
      pathname: ''
    }
  }
}

export default ProductCard
