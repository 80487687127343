import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  setError: ['error', 'message']
})

const INITIAL_STATE = {
  error: false,
  message: ''
}

const setError = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  message: action.message
})

export const HANDLERS = {
  [Types.SET_ERROR]: setError
}

export default createReducer(INITIAL_STATE, HANDLERS)
