import moment from 'moment'

export const checkCPF = (strCPF) => {
  if (!strCPF) return false
  let sum = 0
  let rest
  const cpf = strCPF.replace(/[^0-9]/g, '')
  if (cpf === '00000000000') return false

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }
  rest = (sum * 10) % 11
  if (rest === 10) rest = 0
  if (rest !== parseInt(cpf.substring(9, 10))) return false

  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }
  rest = (sum * 10) % 11

  if (rest === 10) rest = 0
  if (rest !== parseInt(cpf.substring(10, 11))) return false
  return true
}

export const checkMinimumAge = (strBirth) => {
  if (moment(strBirth, 'DD/MM/YYYY', true).isValid()) {
    return moment().diff(moment(strBirth, 'DD/MM/YYYY'), 'years') >= 18
  }
  return false
}

export const checkBirthdate = (strBirth) => {
  if (moment(strBirth, 'DD/MM/YYYY', true).isValid()) {
    return moment(strBirth, 'DD/MM/YYYY').year() >= 1900
  }
  return false
}
