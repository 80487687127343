import React from 'react'
import PropTypes from 'prop-types'
import { BackButton } from '../../components/Button/IconButtons'
import { isZelasTheme } from '../../utils/getTheme'

const HeaderNavigation = ({ clickHandler }) => (
  <div className={`header__navigation ${isZelasTheme() && 'zelas__header__navigation'}`}>
    <BackButton
      className={`header__navigation__back ${isZelasTheme() && 'zelas__header__navigation__back'}`}
      onClickHandler={clickHandler}
    >
      Voltar
    </BackButton>
  </div>
)

HeaderNavigation.propTypes = {
  clickHandler: PropTypes.func
}

HeaderNavigation.defaultProps = {
  clickHandler: () => {
  }
}

export default HeaderNavigation
