import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  fetchUtm: ['utm']
})

const INITIAL_STATE = {}

const getUtm = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.utm.utm
})

export const HANDLERS = {
  [Types.FETCH_UTM]: getUtm
}

export default createReducer(INITIAL_STATE, HANDLERS)
