import React from 'react'
import { isZelasTheme } from '../../utils/getTheme'
import './BoxCard.scss'

function BoxCard (props) {
  const {
    name,
    icon,
    text,
    onClick,
    onChange,
    radio = false,
    selected = false,
    variant = ''
  } = props

  return (
    <button
      className={`
        BoxCard
        ${isZelasTheme() ? 'zelasBoxCard' : ''}
        ${variant ? 'BoxCard--'.concat(variant) : ''}
        ${selected ? 'BoxCard--selected' : ''}
        ${selected && isZelasTheme() ? 'zelasBoxCard--selected' : ''}
      `}
      name={name}
      onClick={onClick}
      onChange={onChange}
      type='button'
    >
      {radio && (
        <label className='BoxCard__ribbon'>
          <input className='BoxCard__ribbon__radio' type='radio' />
          <span className='BoxCard__ribbon__dot' />
        </label>
      )}

      <>
        <span className='BoxCard__icon'>{icon}</span>
        <span className='BoxCard__text'>{text}</span>
      </>
    </button>
  )
}

export default BoxCard
