import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  fetchCep: ['address'],
  fetchNumber: ['address'],
  fetchComplement: ['address'],
  fetchNeighborhood: ['address'],
  fetchCity: ['address'],
  fetchState: ['address'],
  fetchStreet: ['address'],
  checkWired: ['isWired'],
  getAddress: ['address']
})

const INITIAL_STATE = {
  zipCode: '',
  isWired: false,
  cityCode: '',
  street: '',
  number: '',
  neighborhood: '',
  city: '',
  complement: '',
  state: ''
}

const getCepValue = (state = INITIAL_STATE, action) => ({
  ...state,
  zipCode: action.address.zipCode
})

const getNumberValue = (state = INITIAL_STATE, action) => ({
  ...state,
  number: action.address.number
})

const getComplementValue = (state = INITIAL_STATE, action) => ({
  ...state,
  complement: action.address.complement
})

const getNeighborhoodValue = (state = INITIAL_STATE, action) => ({
  ...state,
  neighborhood: action.address.neighborhood
})

const getCityValue = (state = INITIAL_STATE, action) => ({
  ...state,
  city: action.address.city
})

const getStateValue = (state = INITIAL_STATE, action) => ({
  ...state,
  state: action.address.state
})

const getStreetValue = (state = INITIAL_STATE, action) => ({
  ...state,
  street: action.address.street
})

const handleWired = (state = INITIAL_STATE, action) => ({
  ...state,
  isWired: action.isWired
})

const getAddressData = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.address
})

export const HANDLERS = {
  [Types.FETCH_CEP]: getCepValue,
  [Types.FETCH_NUMBER]: getNumberValue,
  [Types.FETCH_NEIGHBORHOOD]: getNeighborhoodValue,
  [Types.FETCH_CITY]: getCityValue,
  [Types.FETCH_STATE]: getStateValue,
  [Types.FETCH_STREET]: getStreetValue,
  [Types.FETCH_COMPLEMENT]: getComplementValue,
  [Types.CHECK_WIRED]: handleWired,
  [Types.GET_ADDRESS]: getAddressData
}

export default createReducer(INITIAL_STATE, HANDLERS)
