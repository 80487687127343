import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  fetchDate: ['date'],
  fetchPeriod: ['period']
})

const INITIAL_STATE = {
  firstDate: '',
  firstDatePeriod: undefined,
  secondDate: '',
  secondDatePeriod: undefined
}

const getDateValue = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.date
})

const getPeriodValue = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.period
})

export const HANDLERS = {
  [Types.FETCH_DATE]: getDateValue,
  [Types.FETCH_PERIOD]: getPeriodValue
}

export default createReducer(INITIAL_STATE, HANDLERS)
