import { gql } from 'apollo-boost'

export default gql`
  {
    allBotScripts {
      edges {
        node {
          analyst {
            name
            avatar
          }
          scripts {
            text
            route
            provider
          }
          tips {
            text: text_format
            producttype
            tiptype
            productid
          }
          warnings {
            text
            type
            provider
          }
        }
      }
    }
  }
`
