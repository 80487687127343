import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import appReducer from './app'
import cartReducer from './cart'
import productsReducer from './products'
import addressReducer from './address'
import userReducer from './user'
import installationReducer from './installation'
import invoiceReducer from './invoice'
import errorReducer from './error'
import paymentReducer from './payment'
import utmReducer from './utm'

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    cart: cartReducer,
    products: productsReducer,
    address: addressReducer,
    user: userReducer,
    installation: installationReducer,
    invoice: invoiceReducer,
    error: errorReducer,
    payment: paymentReducer,
    utm: utmReducer
  })

export default reducers
