import React from 'react'
import SeeDetails from '../../components/SeeDetails'
import DetailsCart from '../../components/DetailsCart'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Creators } from '../../store/ducks/app'

const { toggleDetailsCart } = Creators

const Details = ({
  isDetailsCartActive,
  toggleCartConnected,
  cartOrders,
  products,
  isLoading,
  cartProduct,
  directDebit,
  electronicInvoicing
}) => {
  return (
    <section className='details'>
      <SeeDetails
        isLoading={isLoading}
        isDetailsCartActive={isDetailsCartActive}
        clickHandler={toggleCartConnected}
        cartProduct={cartProduct}
        directDebit={directDebit}
        electronicInvoicing={electronicInvoicing}
      />

      <DetailsCart
        isDetailsCartActive={isDetailsCartActive}
        cartOrders={cartOrders}
        cartProduct={cartProduct}
        products={products}
        directDebit={directDebit}
        electronicInvoicing={electronicInvoicing}
      />
    </section>
  )
}

const mapStateToProps = store => ({
  isDetailsCartActive: store.app.isDetailsCartActive,
  isLoading: store.app.isLoading,
  cartOrders: store.cart,
  products: store.products,
  cartProduct: store.cart.cartProduct,
  directDebit: store.payment.directDebit,
  electronicInvoicing: store.invoice.electronicInvoicing
})

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleCartConnected: toggleDetailsCart
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Details)
