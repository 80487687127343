import React from 'react'
import uuidv1 from 'uuid/v1'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import ProductCard from '../../components/ProductCard'
import { addQuantityThunk, removeQuantityThunk, toggleOptionalsThunk } from '../../store/ducks/thunks'
import './Products.scss'

const Products = ({
  products,
  selectedPlan,
  cartHandler,
  channelsHandler,
  analyst,
  discovery,
  cart,
  toggleOptionalsConnected,
  addQuantityConnected,
  removeQuantityConnected,
  history,
  app,
  productsTips,
  productType
}) => (
  <section className='products'>
    <div className='products__grid'>
      {products.map(product => {
        const productTips = productsTips.find((tip) => {
          return tip.product_id === product.productId
        })
        return (
          <ProductCard
            key={uuidv1()}
            selectedPlan={selectedPlan}
            cartHandler={cartHandler}
            channelsHandler={channelsHandler}
            optionalsHandler={toggleOptionalsConnected}
            addQuantityHandler={addQuantityConnected}
            removeQuantityHandler={removeQuantityConnected}
            analyst={analyst}
            discovery={discovery}
            cart={cart}
            history={history}
            tips={app.botTips}
            productTips={productTips}
            productType={productType}
            {...product}
          />
        )
      })
      }

      <ProductCard
        skipTitle='Não quero este produto'
        selectedPlan={selectedPlan}
        cartHandler={cartHandler}
        analyst={analyst}
        history={history}
        discovery={discovery}
        tips={app.botTips}
      />
    </div>
  </section>
)

Products.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape()),
  selectedPlan: PropTypes.any,
  cart: PropTypes.object.isRequired,
  cartHandler: PropTypes.func,
  channelsHandler: PropTypes.func,
  optionalsHandler: PropTypes.func,
  addQuantityHandler: PropTypes.func,
  removeQuantityHandler: PropTypes.func,
  history: PropTypes.object,
  productsTips: PropTypes.array.isRequired
}

Products.defaultProps = {
  products: [],
  productsTips: [],
  selectedPlan: '',
  loading: true,
  history: {},
  cartHandler: () => {
  },
  channelsHandler: () => {
  },
  optionalsHandler: () => {
  },
  addQuantityHandler: () => {
  },
  removeQuantityHandler: () => {
  }
}

const mapStateToProps = store => ({
  analyst: store.app.analyst,
  discovery: store.cart.discovery,
  cart: store.cart,
  app: store.app
})

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleOptionalsConnected: toggleOptionalsThunk,
  addQuantityConnected: addQuantityThunk,
  removeQuantityConnected: removeQuantityThunk
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Products)
