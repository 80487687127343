import React from 'react'

import PropTypes from 'prop-types'
import { CloseButton } from '../../components/Button/IconButtons'
import Button from '../../components/Button'
import {
  convertStringToLowerCase,
  unitPrice
} from '../../utils/productHandlers'
import './ItemCart.scss'
import { isZelasTheme } from '../../utils/getTheme'

const routeByProductType = type => {
  switch (type) {
    case 'internet':
      return '/internet/'

    case 'phone':
      return '/telefone/'

    case 'tv':
      return '/tv/'

    default:
      return '/produtos-adicionais/'
  }
}

const productTitle = {
  'internet': 'Internet',
  'tv': 'Tv',
  'phone': 'Telefone'

}

const ItemCart = ({
  product,
  prodByType,
  isCombo,
  removeProduct,
  handleChange
}) => {
  if (!product.id) {
    return null
  }

  return (
    <div className='itemCart'>
      <strong className={`itemCart__title ${isZelasTheme() && 'zelas__itemCart__title'}`}>
        {product.quantity ? `${product.quantity}x ` : ''}
        {productTitle[product.kind]}:
      </strong>

      <div className='itemCart__itemWrapper'>
        <div className='itemCart__item'>
          <span className='itemCart__itemName'>
            {convertStringToLowerCase(`${product.name}`)}
          </span>

          <Button
            className={`itemCart__itemChange ${isZelasTheme() && 'zelas__itemCart__itemChange'}`}
            onClickHandler={() => handleChange(routeByProductType(product.kind))}
          >
            Alterar
          </Button>
        </div>

        {product.id &&
        (<CloseButton
          onClickHandler={e => removeProduct('0', product.kind)}
          className='itemCart__itemRemove'
        />)
        }
      </div>
      {(isCombo &&
        (product.price === unitPrice(prodByType, product)
          ? <strong className='itemCart__price'>
              Por R$ {product.price}
          </strong>
          : <strong className='itemCart__price'>
              De R$ {unitPrice(prodByType, product)} por
              R$ {product.price}
          </strong>
        )
      ) || (
        <strong className='itemCart__price'>
          Por R$ {product.price}
        </strong>
      )}
    </div>
  )
}

ItemCart.propTypes = {
  product: PropTypes.object,
  prodByType: PropTypes.array,
  isCombo: PropTypes.bool,
  description: PropTypes.string,
  price: PropTypes.number,
  removeProduct: PropTypes.func,
  history: PropTypes.object
}

ItemCart.defaultProps = {
  product: {},
  prodByType: [],
  isCombo: false,
  description: '',
  price: 0,
  history: {},
  removeProduct: () => {
  }
}

export default ItemCart
