import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { addProductThunk } from '../../store/ducks/thunks'
import Products from '../../containers/Products'
import CtaButton from '../../components/Button/CtaButton'
import { Query } from 'react-apollo'
import internetProductTips from './internetProductTips.query.js'

const Internet = ({
  products,
  loading,
  selectedInternetPlan,
  addInternetConnected,
  history
}) => {
  return (
    <Query query={internetProductTips} >
      {({ data }) => {
        const sortedByDownload = products.sort(
          (a, b) => (parseInt(a.name.split(' ')[0]) >
            parseInt(b.name.split(' ')[0])
          ) ? 1 : -1
        )
        const tips = data.allProducttipss ? data.allProducttipss.edges[0].node.tips : []
        return (
          <Fragment>
            <Products
              products={sortedByDownload}
              loading={loading}
              cartHandler={(id) => addInternetConnected(id, 'internet')}
              selectedPlan={selectedInternetPlan}
              history={history}
              productsTips={tips}
              productType='internet'
            />

            {
              history.location.pathname !== '/carrinho/' &&
              (
                selectedInternetPlan !== ''
                  ? (<CtaButton onClickHandler={() => history.push('/tv/')} />)
                  : (<CtaButton isDisabled />)
              )
            }
          </Fragment>
        )
      }}
    </Query>
  )
}

const mapStateToProps = store => ({
  products: store.products.internet,
  loading: store.products.loading,
  selectedInternetPlan: store.cart.internet
})

const mapDispatchToProps = dispatch => bindActionCreators({
  addInternetConnected: addProductThunk
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Internet))
