export const states = {
  Acre: 'AC',
  Alagoas: 'AL',
  Amapá: 'AP',
  Amazonas: 'AM',
  Bahia: 'BA',
  Ceará: 'CE',
  'Distrito Federal': 'DF',
  'Espírito Santo': 'ES',
  Goiás: 'GO',
  Maranhão: 'MA',
  'Mato Grosso': 'MT',
  'Mato Grosso do Sul': 'MS',
  'Minas Gerais': 'MG',
  Pará: 'PA',
  Paraíba: 'PB',
  Paraná: 'PR',
  Pernambuco: 'PE',
  Piauí: 'PI',
  'Rio de Janeiro': 'RJ',
  'Rio Grande do Norte': 'RN',
  'Rio Grande do Sul': 'RS',
  Rondônia: 'RO',
  Roraima: 'RR',
  'Santa Catarina': 'SC',
  'São Paulo': 'SP',
  Sergipe: 'SE',
  Tocantins: 'TO'
}

export const parseStateFromPosition = {
  0: 'AC',
  1: 'AL',
  2: 'AP',
  3: 'AM',
  4: 'BA',
  5: 'CE',
  6: 'DF',
  7: 'ES',
  8: 'GO',
  9: 'MA',
  10: 'MT',
  11: 'MS',
  12: 'MG',
  13: 'PA',
  14: 'PB',
  15: 'PR',
  16: 'PE',
  17: 'PI',
  18: 'RJ',
  19: 'RN',
  20: 'RS',
  21: 'RO',
  22: 'RR',
  23: 'SC',
  24: 'SP',
  25: 'SE',
  26: 'TO'
}
