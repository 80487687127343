import React, { Fragment } from 'react'
import uuidv1 from 'uuid/v1'
import './Channels.scss'

const Channels = ({ channels }) => (
  <Fragment>
    <div className='channels' key={uuidv1()}>
      <h3 className='channels__title'>
          Canais
      </h3>

      <div className='channels__content'>
        {channels.map(channel => (
          <div className='channels__channel' key={uuidv1()}>
            <img src={channel.image || 'https://placehold.it/80x50'} alt={`Canal ${channel}`} />
            <p className='channels__channel__title'>{channel}</p>
          </div>
        ))}
      </div>
    </div>
  </Fragment>
)

export default Channels
