import React from 'react'
import './Error.scss'
import { IoIosWarning } from 'react-icons/io'
const Error = () => {
  return (
    <div className={'error'}>
      <div>
        <IoIosWarning className={'error__icon'} />
        <span className={'error__title'}>Ops!</span>
      </div>
      <span>Aconteceu algum erro inesperado. Por favor, atualize a página.</span>
    </div>
  )
}

export default Error
