import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import Products from '../../containers/Products'
import CtaButton from '../../components/Button/CtaButton'
import { addProductThunk, channelsThunk } from '../../store/ducks/thunks'
import { Query } from 'react-apollo'
import tvProductTips from './tvProductTips.query.js'

const Tv = ({
  products,
  loading,
  selectedTvPlan,
  addTvConnected,
  channelsConnected,
  history
}) => (
  <Query query={tvProductTips} >
    {({ data }) => {
      const sortedByPriceProducts = products.sort(
        (a, b) => (parseInt(a.price.replace(/,/g, '')) >
          parseInt(b.price.replace(/,/g, ''))
        ) ? 1 : -1
      )
      const tips = data.allProducttipss ? data.allProducttipss.edges[0].node.tips : []
      return (
        <Fragment>
          <Products
            products={sortedByPriceProducts}
            loading={loading}
            cartHandler={(id) => addTvConnected(id, 'tv')}
            channelsHandler={channelsConnected}
            selectedPlan={selectedTvPlan}
            history={history}
            productsTips={tips}
            productType='tv'
          />

          {history.location.pathname !== '/carrinho/' &&
        (selectedTvPlan !== ''
          ? (<CtaButton onClickHandler={() => history.push('/telefone/')} />)
          : (<CtaButton isDisabled />)
        )
          }
        </Fragment>
      )
    }}
  </Query>
)

const mapStateToProps = store => ({
  products: store.products.tv,
  loading: store.products.loading,
  selectedTvPlan: store.cart.tv
})

const mapDispatchToProps = dispatch => bindActionCreators({
  addTvConnected: addProductThunk,
  channelsConnected: channelsThunk
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tv))
