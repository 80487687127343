import { pathnameToString } from '../utils/productHandlers'
import { isZelasTheme } from '../utils/getTheme'

export const getBotScript = (scripts, pathname = '') => {
  const isZelas = isZelasTheme()
  if (!isZelas) return getDefaultScript(scripts, pathname)
  return getZelasScript(scripts, pathname)
}

const getZelasScript = function (scripts, pathname) {
  let text = ''
  scripts.forEach(script => {
    const isPathScript = script.route === pathname
    const hasZelasScript = !!text
    const isZelasScript =
      script.route === pathname && script.provider === 'zelas'

    if ((isPathScript && !hasZelasScript) || isZelasScript) text = script.text
  })
  return text
}

const getDefaultScript = function (scripts, pathname) {
  const { text } =
    scripts.find(script => {
      return script.route === pathname && script.provider !== 'zelas'
    }) || {}
  return text
}

export const getBotTip = (tips, tipType = '', productType = 'este produto', product = '') => {
  const tip =
    tips.find(tip => {
      if (!tip.producttype || (!tip.producttype && !tip.product)) return tip.tiptype === tipType
      if (!tip.product) return tip.tiptype === tipType && tip.producttype === productType
      return tip.tiptype === tipType && tip.producttype === productType && tip.product === product + ''
    }) || {}
  return tip
}

export const getBotWarning = (warnings, type) => {
  const provider = isZelasTheme() ? 'zelas' : ''
  const { text } =
    warnings.find(warning => {
      if (!warning.provider) return warning.type === type
      return warning.provider === provider && warning.type === type
    }) || {}
  return text
}

export const convertPathnameToProductType = location => {}

export function hasProductType (tips, tipType = '') {
  return tips.some(
    product =>
      product.tiptype === tipType &&
      product.producttype !== 'undefined' &&
      product.producttype !== null
  )
}

export const getTipFromDiscovery = (dicovery, location) => {
  if (!location) {
    return ''
  }

  const productType = pathnameToString(location).toUpperCase()
  const objComparison = {
    INTERNET: 'INTERNET',
    TV: 'TV',
    TELEFONE: 'FONE'
  }

  if (dicovery.productsMissingTips) {
    const itemfound = dicovery.productsMissingTips.find(
      item => item.product_type === objComparison[productType]
    )

    if (!itemfound) {
      return ''
    }

    return itemfound.missing_tips
  }

  return ''
}
