import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import { isZelasTheme } from '../../utils/getTheme'
import { pageDefaultsThunk, loadDefaultsThunk } from '../../store/ducks/thunks'
import SharedContainers from '../SharedContainers'
import botScript from './botScript.query.js'
import './App.scss'

const App = ({
  children,
  history,
  pageDefaultsConnected,
  setBot
}) => (
  <Query query={botScript} >
    {({ loading, data }) => {
      if (!loading && data && data.allBotScripts) {
        setBot(data.allBotScripts.edges[0].node)
      }

      return (
        <div
          className={`app ${isZelasTheme() && 'app--zelas'} ${history.location.pathname === '/' && 'app--noDetails'}`}
          onLoad={() => pageDefaultsConnected(history)}
        >
          <SharedContainers history={history}>
            {children}
          </SharedContainers>
        </div>
      )
    }}
  </Query>
)

App.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  pageDefaultsConnected: PropTypes.func,
  setBot: PropTypes.func
}

App.defaultProps = {
  pageDefaultsConnected: () => {},
  setBot: () => {}
}

const mapStateToProps = store => ({
  isDetailsCartActive: store.app.isDetailsCartActive
})

const mapDispatchToProps = dispatch => bindActionCreators({
  pageDefaultsConnected: pageDefaultsThunk,
  setBot: (scriptData) => loadDefaultsThunk(scriptData)
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
