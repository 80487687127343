import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FaCheckCircle } from 'react-icons/fa'
import { getProductById } from '../../utils/productHandlers'
import Prices from '../Prices'

import './DetailsCart.scss'

const RenderProductPrice = function ({ title, productId, products }) {
  const { name } = getProductById(productId, products) || {}
  return (
    <p>
      <span className='detailsCart__icon'>
        <FaCheckCircle />
      </span>
      {`${title} ${name}`}
    </p>
  )
}

const RenderProductsPrice = function ({ cartOrders, cartProduct, products, directDebit, electronicInvoicing }) {
  return (
    <Fragment>
      {cartOrders.tv !== '0' && <RenderProductPrice productId={cartOrders.tv} products={products.tv} title='Tv' />}
      {cartOrders.internet !== '0' && <RenderProductPrice productId={cartOrders.internet} products={products.internet} title='Internert' />}
      {cartOrders.phone !== '0' && <RenderProductPrice productId={cartOrders.phone} products={products.phone} title='Telefone' />}
      <div className='detailsCart__fromTo'>
        <Prices
          product={cartProduct}
          directDebit={directDebit}
          electronicInvoicing={electronicInvoicing}
        />
      </div>
    </Fragment>
  )
}

const DetailsCart = ({ isDetailsCartActive, cartOrders, cartProduct, products, directDebit, electronicInvoicing }) => {
  const internetProduct = cartOrders.internet
  const tvProduct = cartOrders.tv
  const phoneProduct = cartOrders.phone
  const hasProduct = internetProduct !== '0' || tvProduct !== '0' || phoneProduct !== '0'

  return (
    <div className={`detailsCart ${isDetailsCartActive ? 'detailsCart--active' : ''}`}>
      {hasProduct
        ? <RenderProductsPrice
          cartOrders={cartOrders}
          cartProduct={cartProduct}
          products={products}
          directDebit={directDebit}
          electronicInvoicing={electronicInvoicing}
        />
        : <span className='detailsCart__empty'>Seu carrinho está vazio :(</span>}
    </div>
  )
}

DetailsCart.propTypes = {
  isDetailsCartActive: PropTypes.bool
}

DetailsCart.defaultProps = {
  isDetailsCartActive: false
}

export default DetailsCart
