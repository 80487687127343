import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RichText } from 'prismic-reactjs'
import { string, object } from 'yup'

import {
  handleCepThunk,
  handleCityThunk,
  handleComplementThunk,
  handleCpfThunk,
  handleEmailThunk,
  handleNeighborhoodThunk,
  handleNumberThunk,
  handleRgThunk,
  handleBirthdayThunk,
  handleStateThunk,
  handleStreetThunk
} from '../../store/ducks/thunks'
import { getBotTip, getBotWarning } from '../../utils/botScript'
import Message from '../../components/Message'
import CtaButton from '../../components/Button/CtaButton'
import { inputMask } from '../../utils/formMasks'
import { Input } from '../../components/Inputs'
import FormHandler from '../../components/FormHandler'
import AddressForm from '../../containers/AddressForm'
import { checkCPF, checkBirthdate, checkMinimumAge } from '../../utils/checkFields'
const Checkout = props => {
  const {
    zipCode,
    street,
    neighborhood,
    city,
    state,
    number,
    complement,
    rg,
    birthday,
    cpf,
    email,
    handleNumberConnected,
    handleComplementConnected,
    handleEmailConnected,
    handleCepConnected,
    handleRgConnected,
    handleBirthdayConnected,
    handleCpfConnected,
    handleNeighborhoodConnected,
    handleCityConnected,
    handleStateConnected,
    handleStreetConnected,
    history,
    botTips,
    botWarnings
  } = props
  const addressConfirmationTip = getBotTip(botTips, 'addressConfirmation', null).text
  const safeSiteNotice = getBotWarning(botWarnings, 'notice')
  const safetyDisclaimer = getBotWarning(botWarnings, 'disclaimer')

  const validationSchema = object().shape({
    email: string().email('Esse e-mail não é válido. Tente incluir outro.').required('Você precisa incluir um e-mail válido.'),
    cpf: string()
      .test('validate cpf', 'Você precisa incluir um CPF válido.', value => {
        return checkCPF(value)
      }),
    rg: string().required('Você precisa incluir um RG válido.'),
    birthday: string().required('Aqui, você precisa incluir sua data de nascimento.')
      .test('validate date', 'Você precisa incluir uma data válida.', value => {
        return checkBirthdate(value)
      })
      .test('validate min date', 'Você precisa ser maior de 18 anos.', value => {
        return checkMinimumAge(value)
      }),
    zipCode: string().required('Insira um CEP válido para continuar.').length(9, 'Insira um CEP válido para continuar.'),
    neighborhood: string().required('Inclua seu bairro para continuar.'),
    state: string().required('Inclua seu estado para continuar.'),
    city: string().required('Inclua sua cidade para continuar.'),
    street: string().required('Inclua sua rua para continuar.'),
    number: string().required('Insira o número da sua residência aqui.')
  })

  const initialValues = {
    zipCode: inputMask('cep', zipCode),
    street,
    neighborhood,
    city,
    state,
    number,
    complement,
    rg,
    birthday,
    cpf: inputMask('cpf', cpf),
    email
  }

  const handleSubmit = values => {
    handleEmailConnected(values.email)
    handleCpfConnected(values.cpf)
    handleRgConnected(values.rg)
    handleBirthdayConnected(values.birthday)
    handleCepConnected(values.zipCode)
    handleNeighborhoodConnected(values.neighborhood)
    handleStateConnected(values.state)
    handleCityConnected(values.city)
    handleStreetConnected(values.street)
    handleNumberConnected(values.number)
    handleComplementConnected(values.complement)

    history.push('/fatura/')
  }

  return (
    <Fragment>
      <FormHandler
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <CheckoutForm
          {...props}
          addressConfirmationTip={addressConfirmationTip}
          safeSiteNotice={safeSiteNotice}
          safetyDisclaimer={safetyDisclaimer}
        />
      </FormHandler>
    </Fragment>
  )
}

const CheckoutForm = ({ values, touched, errors, handleChange, handleBlur, isSubmitting, analystName, avatarUrl, safetyDisclaimer, safeSiteNotice, addressConfirmationTip }) =>
  <Fragment>
    <Input
      type='email'
      name='email'
      label={'E-mail'}
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.email}
      error={errors.email && touched.email ? errors.email : null}
    />

    <Input
      type='tel'
      name='cpf'
      onChange={handleChange}
      onBlur={handleBlur}
      value={inputMask('cpf', values.cpf)}
      label='CPF'
      error={errors.cpf && touched.cpf ? errors.cpf : null}
    />

    <Input
      type='tel'
      name='rg'
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.rg}
      label='RG'
      error={errors.rg && touched.rg ? errors.rg : null}
    />

    <Input
      type='tel'
      name='birthday'
      onChange={handleChange}
      onBlur={handleBlur}
      value={inputMask('date', values.birthday)}
      maxLength='10'
      label='Nascimento'
      error={errors.birthday && touched.birthday ? errors.birthday : null}
    />

    {safetyDisclaimer && (
      <Message
        name={analystName}
        avatarUrl={avatarUrl}
        message={RichText.render(safetyDisclaimer)}
      />
    )}
    {safeSiteNotice && (
      <Message
        name={analystName}
        message={RichText.render(safeSiteNotice)}
        secondary
      />
    )}
    {addressConfirmationTip && <Message
      name={analystName}
      avatarUrl={avatarUrl}
      message={RichText.render(addressConfirmationTip)}
    />}

    <AddressForm
      values={values}
      touched={touched}
      errors={errors}
      handleChange={handleChange}
      handleBlur={handleBlur}
      blockEdit
    />

    <CtaButton
      type='submit'
      disabled={isSubmitting || Object.keys(errors).length > 0}
    >
      Próximo passo
    </CtaButton>
  </Fragment>

const mapDispatchToProps = dispatch => bindActionCreators({
  handleCepConnected: handleCepThunk,
  handleNumberConnected: handleNumberThunk,
  handleComplementConnected: handleComplementThunk,
  handleNeighborhoodConnected: handleNeighborhoodThunk,
  handleCityConnected: handleCityThunk,
  handleStateConnected: handleStateThunk,
  handleStreetConnected: handleStreetThunk,
  handleRgConnected: handleRgThunk,
  handleBirthdayConnected: handleBirthdayThunk,
  handleEmailConnected: handleEmailThunk,
  handleCpfConnected: handleCpfThunk
}, dispatch)

const mapStateToProps = store => ({
  zipCode: store.address.zipCode,
  street: store.address.street,
  neighborhood: store.address.neighborhood,
  city: store.address.city,
  state: store.address.state,
  number: store.address.number,
  complement: store.address.complement,
  cpf: store.user.cpf,
  rg: store.user.rg,
  birthday: store.user.birthday,
  email: store.user.email,
  analystName: store.app.analyst.name,
  avatarUrl: store.app.analyst.avatarUrl,
  isLoading: store.app.isLoading,
  botTips: store.app.botTips,
  botWarnings: store.app.botWarnings
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
