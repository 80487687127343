const formatPrice = value => parseFloat(value).toFixed(2).replace('.', ',')

const sumPrices = (value = 0, extraPrice = 0) => parseFloat(value) + parseFloat(extraPrice)

const getTotalPrice = (product, directDebit, electronicInvoicing) => {
  if (!product.price) {
    return formatPrice(0)
  }

  const prices = Object.freeze({
    price: product.price.replace(',', '.'),
    extraPriceNoDirectDebit: product.extraPriceNoDirectDebit.replace(',', '.'),
    extraPriceNoElectronicInvoice: product.extraPriceNoElectronicInvoice.replace(',', '.'),
    extraPriceNoDirectDebitElectronicInvoice: product.extraPriceNoDirectDebitElectronicInvoice.replace(',', '.')
  })

  if (!directDebit && electronicInvoicing) {
    return formatPrice(sumPrices(prices.price, prices.extraPriceNoDirectDebit))
  }

  if (directDebit && !electronicInvoicing) {
    return formatPrice(sumPrices(prices.price, prices.extraPriceNoElectronicInvoice))
  }

  if (!directDebit && !electronicInvoicing) {
    return formatPrice(sumPrices(prices.price, prices.extraPriceNoDirectDebitElectronicInvoice))
  }

  return formatPrice(prices.price)
}

export default getTotalPrice
