import React, { Fragment } from 'react'
import CtaButton from '../../components/Button/CtaButton'

const NotWired = ({ history }) => (
  <Fragment>
    <CtaButton text='Vamos lá!' onClickHandler={() => window.location.href = 'https://assineaclarotv.com.br'} />
  </Fragment>
)

export default NotWired
