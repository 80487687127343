import api from '../services/api'
import { capitalize } from 'lodash'

export const getInternetPlans = ({ results }) => results
  .filter(product => product.code_type === 'INTERNET')

export const getTvPlans = ({ results }) => results
  .filter(product => product.code_type === 'HDTV')

export const getPhonePlans = ({ results }) => results
  .filter(product => product.code_type === 'FONE')

export const getProductById = (id, data) => data
  .find(product => product.providerId === id)

export const getProductByIdFromDiscovery = (optionalsArray, discoveryArray) => {
  if (!discoveryArray || !optionalsArray) {
    return []
  }

  return optionalsArray.reduce((acc, current) => {
    const itemFound = discoveryArray.find(item => (
      current.id === item.optional_technology_id ||
      current.id === item.spot_id ||
      current.id === item.optional_product_id
    ))

    let product_type = ''
    let price = ''
    let quantity = 1

    if (current.hasOwnProperty('quantity')) {
      quantity = current.quantity
    }

    if (itemFound) {
      if (itemFound.hasOwnProperty('optional_technology_id')) {
        product_type = 'TECNOLOGIA ADICIONAL'
      }

      if (itemFound.hasOwnProperty('spot_id')) {
        product_type = 'PONTO ADICIONAL'
      }

      if (itemFound.hasOwnProperty('optional_product_id')) {
        product_type = 'PRODUTO ADICIONAL'
      }

      if (
        itemFound.hasOwnProperty('price') ||
        itemFound.hasOwnProperty('monthly_price')
      ) {
        price = itemFound.price || itemFound.monthly_price
      }

      const obj = {
        ...current,
        ...itemFound,
        product_type,
        price: (price * quantity),
        product_name: itemFound.name
      }
      acc.push(obj)
    }

    return acc
  }, [])
}

export const getPriceFrom = cartOrders => (
  cartOrders.reduce((acc, cur) => acc + cur.price, 0)
)

export const filterSingleProducts = data => data
  .filter(product => product.packages.split(',').includes('SINGLE'))

export const filterActiveProducts = data => data
  .filter(product => product.show_in_cart === 'active')

export const convertPriceToString = (price) => {
  price = parseFloat(Math.round(price * 100) / 100).toFixed(2)
  const priceString = price.toString()
  return priceString.replace('.', ',')
}

export const pathnameToString = (pathname) => {
  if (!pathname) {
    return ''
  }

  return pathname.split('/').join('')
}

export const getProductsFromCart = cart => (cart ? (cart.products || []) : [])

export const getOldPriceById = (id, { internet, tv, phone }) =>
  ([...internet, ...tv, ...phone].find(item => item.product_id === id) || {}).price

export const getDiscoveryPriceById = (id, discovery) => {
  if (!id) {
    return 0
  }

  if (!discovery) {
    return 0
  }

  const properties = Object.keys(discovery)
  const dicoveryProperties = [
    'optionalSpots',
    'optionalTechnologies',
    'optionalProducts',
    'products'
  ]

  const itemsFound = properties.reduce((acc, current) => {
    if (!dicoveryProperties.includes(current) || !discovery[current]) {
      return acc
    }

    const itemFound = discovery[current].find(item => {
      return (
        item.optional_technology_id === id ||
        item.spot_id === id ||
        item.optional_product_id === id ||
        item.product_id === id
      )
    })

    let price = ''

    if (itemFound) {
      if (
        itemFound.hasOwnProperty('price') ||
        itemFound.hasOwnProperty('monthly_price')
      ) {
        price = itemFound.price || itemFound.monthly_price
      }

      acc = price
    }

    return acc
  }, '')

  return itemsFound
}

export const getDiscoveryInfo = (discovery) => {
  if (!discovery) {
    return {}
  }

  let response = {}
  response.price = (discovery.products || []).reduce((acc, cur) => acc + cur.price, 0)
  response.loyalty = (discovery.policies || []).reduce((acc, cur) => (cur.loyalty > acc ? cur.loyalty : acc), 0)
  response.fineAmount = (discovery.policies || []).reduce((acc, cur) => acc + cur.fine_amount, 0)
  response.adhesionFee = (discovery.policies || []).reduce((acc, cur) => acc + cur.adhesion_fee, 0)

  return response
}

export const getOptionalsFromDiscovery = (cartOrders, discovery) => {
  if (!discovery) {
    return []
  }

  if (!cartOrders) {
    return []
  }

  const cartProperties = Object.keys(cartOrders)
  const excludes = [
    'discovery',
    'internet',
    'tv',
    'phone'
  ]

  return cartProperties.reduce((acc, property) => {
    if (excludes.includes(property) || !cartOrders[property]) {
      return acc
    }

    if (cartOrders[property].length > 0 && discovery[property]) {
      const itemsFound = cartOrders[property].reduce((accumulator, order) => {
        const itemFound = discovery[property].find(item => (
          order.id === item.optional_technology_id ||
          order.id === item.spot_id ||
          order.id === item.optional_product_id
        ))

        if (itemFound) {
          let price = ''
          let quantity = 1

          if (order.hasOwnProperty('quantity')) {
            quantity = order.quantity
          }

          if (
            itemFound.hasOwnProperty('price') ||
            itemFound.hasOwnProperty('monthly_price')
          ) {
            price = itemFound.price || itemFound.monthly_price
          }

          accumulator.push({
            ...itemFound,
            ...order,
            price: (price * quantity)
          })
        }

        return accumulator
      }, [])

      acc.push(...itemsFound)
    }

    return acc
  }, [])
}

export const updateCartInfo = (discovery, cartOrders) => {
  if (!discovery) {
    return {}
  }

  let response = {}
  let allProducts = [
    ...(discovery.products || []),
    ...(getOptionalsFromDiscovery(cartOrders, discovery))
  ]

  response.price = allProducts.reduce((acc, cur) => {
    if (cur) {
      return acc + cur.price
    }

    return acc
  }, 0)

  response.loyalty = (discovery.policies || []).reduce((acc, cur) =>
    (cur.loyalty > acc ? cur.loyalty : acc), 0)

  response.fineAmount = (discovery.policies || []).reduce((acc, cur) =>
    acc + cur.fine_amount, 0)

  response.adhesionFee = (discovery.policies || []).reduce((acc, cur) =>
    acc + cur.adhesion_fee, 0)

  return response
}

export const getComboProduct = (cartProduct, productType) => {
  const product = cartProduct[productType] || {}
  return {
    id: product[`provider${capitalize(productType)}Id`],
    name: product.name,
    price: product.price,
    kind: productType
  }
}

export const comboPrice = product => {
  return (product.price / 100.0).toFixed(2).replace('.', ',')
}

export const unitPrice = (prodByType, product) => {
  return prodByType[Object.keys(prodByType).find(key => prodByType[key]['providerId'] === product.id)]['price']
}

export const updateDetails = (cartOrders, products, discovery) => {
  const properties = Object.keys(cartOrders)
  const excludes = ['discovery', 'optionalProducts', 'optionalSpots', 'optionalTechnologies']
  return properties.reduce((acc, property) => {
    if (excludes.includes(property)) {
      return acc
    }

    if (!Array.isArray(cartOrders[property]) && products[property].length > 0) {
      acc.push(...[getProductById(cartOrders[property], products[property])])
    }

    acc.push(...getProductByIdFromDiscovery(cartOrders[property], discovery[property]))

    return acc
  }, [])
}

export const getCityCode = async cityName => {
  const cities = (await api.getCitiesList()).data
  const city = cities.results.find(city => city.city_name === cityName) || {}
  return city.city_id
}

export const convertStringToLowerCase = string => string && string.toLowerCase()

export const checkOptionalResources = (id, cart) => {
  const properties = Object.keys(cart)

  const itemsFound = properties.reduce((acc, property) => {
    if (!Array.isArray(cart[property])) {
      return acc
    }

    const itemFound = cart[property].find(item => item.id === id)

    if (itemFound) {
      acc = true
    }

    return acc
  }, false)

  return itemsFound
}

export const getItemValue = (id, cart) => {
  const properties = Object.keys(cart)

  return properties.reduce((acc, property) => {
    if (!Array.isArray(cart[property])) {
      return acc
    }

    const itemFound = cart[property].find(item => item.id === id)

    if (itemFound) {
      acc = itemFound.quantity
    }

    return acc
  }, 0)
}

export const getPropertyFromCart = (id, discovery) => {
  const properties = Object.keys(discovery)

  return properties.reduce((acc, property) => {
    if (!Array.isArray(discovery[property])) {
      return acc
    }

    const itemFound = discovery[property].find(item => (
      item.optional_technology_id === id || item.spot_id === id || item.optional_product_id === id)
    )

    if (itemFound) {
      acc = property
    }

    return acc
  }, '')
}

export const excludeItemFromArray = (id, property, items) => items[property]
  .filter(item => item.id !== id)

export const addItemQuantityFromArray = (id, property, cart) => {
  return cart[property].reduce((acc, product) => {
    if (product.id !== id) {
      acc.push(product)

      return acc
    }

    product.quantity++

    acc.push({ id: product.id, quantity: product.quantity })

    return acc
  }, [])
}

export const removeItemQuantityFromArray = (id, property, cart) => {
  return cart[property].reduce((acc, product) => {
    if (product.id !== id) {
      acc.push(product)

      return acc
    }

    if (product.quantity > 1) {
      product.quantity--
    }

    acc.push({ id: product.id, quantity: product.quantity })

    return acc
  }, [])
}

export const getTip = (id, discovery) => {
  if (!discovery.products) {
    return false
  }

  return (discovery.products.find(item => item.product_id === id) || {}).tip
}

export const filterProductFromCart = (id, cart) => {
  const properties = Object.keys(cart)
  const excludes = ['discovery']

  return properties.reduce((acc, property) => {
    if (excludes.includes(property) || !cart[property]) {
      return acc
    }

    if (!Array.isArray(cart[property])) {
      if (cart[property] === id) {
        acc = {
          [property]: cart[property]
        }
      }

      return acc
    }

    const itemFound = cart[property].find(item => id === item.id)

    if (itemFound) {
      const newItems = cart[property].filter(item => item.id !== itemFound.id)

      acc = {
        [property]: newItems
      }
    }

    return acc
  }, {})
}
