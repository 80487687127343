import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import Products from '../../containers/Products'
import CtaButton from '../../components/Button/CtaButton'
import { addProductThunk } from '../../store/ducks/thunks'
import { Query } from 'react-apollo'
import phoneProductTips from './phoneProductTips.query.js'

const Phone = ({
  products,
  loading,
  selectedPhonePlan,
  cartProduct,
  addPhoneConnected,
  history
}) => (
  <Query query={phoneProductTips} >
    {({ data }) => {
      const tips = data.allProducttipss ? data.allProducttipss.edges[0].node.tips : []
      return (
        <Fragment>
          <Products
            products={products}
            loading={loading}
            cartHandler={(id) => addPhoneConnected(id, 'phone')}
            selectedPlan={selectedPhonePlan}
            history={history}
            productsTips={tips}
            productType='phone'
          />
          {(!cartProduct.id) &&
            <span style={{ color: '#ff5a57' }}>
              Para continuar, você precisa selecionar um dos produtos disponíveis.
            </span>
          }

          {history.location.pathname !== '/carrinho/' &&
            (selectedPhonePlan !== ''
              ? (<CtaButton
                onClickHandler={() => history.push('/carrinho/')}
                isDisabled={!cartProduct.id}
              />)
              : (<CtaButton isDisabled />)
            )
          }
        </Fragment>
      )
    }}
  </Query>
)

const mapStateToProps = store => ({
  products: store.products.phone,
  loading: store.products.loading,
  selectedPhonePlan: store.cart.phone,
  cartProduct: store.cart.cartProduct
})

const mapDispatchToProps = dispatch => bindActionCreators({
  addPhoneConnected: addProductThunk
}, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Phone))
