import React from 'react'
import PropTypes from 'prop-types'

import getTotalPrice from './getTotalPrice'

const offersType = Object.freeze({
  directDebitDigitalInvoice: 'directDebitDigitalInvoice',
  directDebitPrintedInvoice: 'directDebitPrintedInvoice',
  regularDebitDigitalInvoice: 'regularDebitDigitalInvoice',
  regularDebitPrintedInvoice: 'regularDebitPrintedInvoice'
})

const getOffer = (directDebit, electronicInvoicing) => {
  if (directDebit && !electronicInvoicing) {
    return offersType.directDebitPrintedInvoice
  }
  if (!directDebit && electronicInvoicing) {
    return offersType.regularDebitDigitalInvoice
  }
  if (!directDebit && !electronicInvoicing) {
    return offersType.regularDebitPrintedInvoice
  }
  return offersType.directDebitDigitalInvoice
}

const InvoicePrice = ({ invoicePrice }) => invoicePrice
  ? <p>Custo do boleto R$ {invoicePrice}</p>
  : null

const Prices = ({ product, directDebit, electronicInvoicing }) => {
  const totalPrice = getTotalPrice(product, directDebit, electronicInvoicing)
  const invoicePrice = directDebit ? null : product.extraPriceNoDirectDebit

  if (product && product.extra && product.extra.offers && product.extra.offers[getOffer(directDebit, electronicInvoicing)].periods) {
    return product.extra.offers[getOffer(directDebit, electronicInvoicing)].periods
      .map((item, index) => (
        <div key={index} className='offer'>
          <p>Preço: <b>R$ {item.price}</b> por {item.validity / 30} meses.</p>
          <p>Após período <b>R$ {totalPrice}</b> por mês.</p>
          <InvoicePrice invoicePrice={invoicePrice} />
        </div>
      ))
  }

  return (
    <React.Fragment>
      <p>Preço: <b>R$ {totalPrice}</b> por mês</p>
      <InvoicePrice invoicePrice={invoicePrice} />
    </React.Fragment>
  )
}

Prices.propTypes = {
  product: PropTypes.object,
  directDebit: PropTypes.bool.isRequired,
  electronicInvoicing: PropTypes.bool,
  onlyPrice: PropTypes.bool
}

Prices.defaultProps = {
  onlyPrice: false
}

export default Prices
