import React from 'react'
import PropTypes from 'prop-types'
import Avatar from '../Avatar'
import { ReactComponent as PadLockSVG } from './padlock.svg'
import { OptionalProducts, OptionalSpots, OptionalTechnologies } from '../OptionalResources'
import './Message.scss'

const Message = ({
  name,
  avatarUrl,
  message,
  discovery,
  optionalsHandler,
  addQuantityHandler,
  removeQuantityHandler,
  cart,
  secondary
}) => (
  <div className={`message ${secondary && 'message--secondary'}`}>
    {name && avatarUrl && (
      <div className='message__avatar'>
        <Avatar name={name} avatarUrl={avatarUrl} />
      </div>
    )}

    <div className='message__content'>
      {secondary && <PadLockSVG className='message__avatar__icon' />}

      {message && (<div className='message__avatar__text'>{message}</div>)}

      {discovery.optionalSpots && (
        <OptionalSpots
          discovery={discovery}
          cart={cart}
          optionalsHandler={optionalsHandler}
          addQuantityHandler={addQuantityHandler}
          removeQuantityHandler={removeQuantityHandler}
        />
      )}

      {discovery.optionalTechnologies && (
        <OptionalTechnologies
          discovery={discovery}
          cart={cart}
          optionalsHandler={optionalsHandler}
        />
      )}

      {discovery.optionalProducts && (
        <OptionalProducts
          discovery={discovery}
          cart={cart}
          optionalsHandler={optionalsHandler}
        />
      )}
    </div>
  </div>
)

export default Message

Message.propTypes = {
  name: PropTypes.string,
  avatarUrl: PropTypes.string,
  message: PropTypes.any,
  discovery: PropTypes.any,
  optionalsHandler: PropTypes.func,
  addQuantityHandler: PropTypes.func,
  removeQuantityHandler: PropTypes.func,
  cart: PropTypes.object,
  secondary: PropTypes.bool
}

Message.defaultProps = {
  name: '',
  avatarUrl: '',
  message: '',
  secondary: false,
  discovery: {},
  optionalsHandler: () => {
  },
  addQuantityHandler: () => {
  },
  removeQuantityHandler: () => {
  },
  cart: {}
}
