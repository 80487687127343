import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { RichText } from 'prismic-reactjs'
import Message from '../../components/Message'
import ItemCart from '../../components/ItemCart'
import CtaButton from '../../components/Button/CtaButton'
import ResumeCart from '../../containers/ResumeCart'
import { changeItemsThunk, addProductThunk } from '../../store/ducks/thunks'
import { getBotTip } from '../../utils/botScript'

import { updateCartInfo, getComboProduct } from '../../utils/productHandlers'

const Cart = props => {
  const {
    products,
    cartOrders,
    cartProduct,
    directDebit,
    electronicInvoicing,
    discovery,
    history,
    analyst,
    removeProductConnected,
    changeItemsConnected,
    botTips
  } = props
  const checkoutTip = getBotTip(botTips, 'checkout').text
  const productTypes = ['internet', 'tv', 'phone']
  const singleType = cartProduct.kind
  return (
    <Fragment>
      {cartProduct.kind !== 'combo' && cartProduct.kind &&
      <ItemCart
        product={cartProduct}
        prodByType={products[singleType]}
        removeProduct={removeProductConnected}
        handleChange={changeItemsConnected}
        isCombo={false}
      />
      }
      {!cartProduct.kind && productTypes.map((type, index) => {
        return (
          <ItemCart
            key={index}
            product={getComboProduct(cartProduct, type)}
            prodByType={products[type]}
            removeProduct={removeProductConnected}
            handleChange={changeItemsConnected}
            isCombo
          />
        )
      })
      }

      {checkoutTip && (
        <Message
          name={analyst.name}
          avatarUrl={analyst.avatarUrl}
          message={RichText.render(checkoutTip)}
        />
      )}

      <ResumeCart
        discovery={discovery || {}}
        cartDiscovery={updateCartInfo(discovery, cartOrders)}
        cartProduct={cartProduct}
        products={products}
        directDebit={directDebit}
        electronicInvoicing={electronicInvoicing}
      />
      <CtaButton
        text='Próximo passo'
        onClickHandler={() => history.push('/checkout/')}
      />
    </Fragment>
  )
}

const mapStateToProps = store => ({
  analyst: store.app.analyst,
  products: store.products,
  cartProduct: store.cart.cartProduct,
  discovery: store.cart.discovery,
  botTips: store.app.botTips,
  directDebit: store.payment.directDebit,
  electronicInvoicing: store.invoice.electronicInvoicing
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeProductConnected: addProductThunk,
      changeItemsConnected: changeItemsThunk
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Cart)
)
