import { gql } from 'apollo-boost'

export default gql`
  {
    allProducttipss (uid: "internet-products"){
      pageInfo {
        hasNextPage
        startCursor
        hasPreviousPage
      }
      edges {
        node {
          _meta {
            id
            uid
            type
            tags
            lang
          }
          tips {
            product_id
            product_name
            tip
          }
        }
        cursor
      }
      totalCount
    }
  }
`
