import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  addInternetToCart: ['productId'],
  addTvToCart: ['productId'],
  addComboToCart: ['productId'],
  clearTvFromCart: ['productId'],
  clearOptionalsFromCart: [''],
  addPhoneToCart: ['productId'],
  removeInternetFromCart: ['productId'],
  removeTvFromCart: ['productId'],
  removePhoneFromCart: ['productId'],
  addOptionalTechnologies: ['product'],
  addOptionalSpots: ['product'],
  addOptionalProducts: ['product'],
  removeOptionalTechnologies: ['products'],
  removeOptionalSpots: ['products'],
  removeOptionalProducts: ['products'],
  updateItemQuantity: ['products'],
  fetchDiscovery: ['discoveryCart'],
  cartProduct: ['product']
})

const INITIAL_STATE = {
  internet: '',
  tv: '',
  phone: '',
  combo: '',
  discovery: '',
  optionalTechnologies: [],
  optionalSpots: [],
  optionalProducts: [],
  cartProduct: {}
}

const addInternet = (state = INITIAL_STATE, action) => ({
  ...state,
  internet: action.productId
})

const addTv = (state = INITIAL_STATE, action) => ({
  ...state,
  tv: action.productId
})

const addPhone = (state = INITIAL_STATE, action) => ({
  ...state,
  phone: action.productId
})

const addCombo = (state = INITIAL_STATE, action) => ({
  ...state,
  combo: action.productId
})

const clearTv = (state = INITIAL_STATE, action) => ({
  ...state,
  tv: action.productId,
  optionalTechnologies: [],
  optionalSpots: [],
  optionalProducts: []
})

const clearOptionals = (state = INITIAL_STATE) => ({
  ...state,
  optionalTechnologies: [],
  optionalSpots: [],
  optionalProducts: []
})

const addSpots = (state = INITIAL_STATE, action) => ({
  ...state,
  optionalSpots: [action.product, ...state.optionalSpots]
})

const addProducts = (state = INITIAL_STATE, action) => ({
  ...state,
  optionalProducts: [action.product, ...state.optionalProducts]
})

const addTechnologies = (state = INITIAL_STATE, action) => ({
  ...state,
  optionalTechnologies: [action.product, ...state.optionalTechnologies]
})

const removeSpots = (state = INITIAL_STATE, action) => ({
  ...state,
  optionalSpots: [...action.products]
})

const removeProducts = (state = INITIAL_STATE, action) => ({
  ...state,
  optionalProducts: [...action.products]
})

const removeTechnologies = (state = INITIAL_STATE, action) => ({
  ...state,
  optionalTechnologies: [...action.products]
})

const updateQuantity = (state = INITIAL_STATE, action) => ({
  ...state,
  optionalSpots: [...action.products]
})

const getDiscovery = (state = INITIAL_STATE, action) => ({
  ...state,
  discovery: action.discoveryCart
})

const removeInternet = (state = INITIAL_STATE, action) => ({
  ...state,
  internet: ''
})

const removeTv = (state = INITIAL_STATE, action) => ({
  ...state,
  tv: ''
})

const removePhone = (state = INITIAL_STATE, action) => ({
  ...state,
  phone: ''
})

const setCartProduct = (state = INITIAL_STATE, action) => ({
  ...state,
  cartProduct: action.product
})

export const HANDLERS = {
  [Types.ADD_INTERNET_TO_CART]: addInternet,
  [Types.ADD_TV_TO_CART]: addTv,
  [Types.ADD_PHONE_TO_CART]: addPhone,
  [Types.ADD_COMBO_TO_CART]: addCombo,
  [Types.ADD_OPTIONAL_PRODUCTS]: addProducts,
  [Types.ADD_OPTIONAL_SPOTS]: addSpots,
  [Types.CLEAR_TV_FROM_CART]: clearTv,
  [Types.CLEAR_OPTIONALS_FROM_CART]: clearOptionals,
  [Types.ADD_OPTIONAL_TECHNOLOGIES]: addTechnologies,
  [Types.REMOVE_OPTIONAL_PRODUCTS]: removeProducts,
  [Types.REMOVE_OPTIONAL_SPOTS]: removeSpots,
  [Types.REMOVE_OPTIONAL_TECHNOLOGIES]: removeTechnologies,
  [Types.UPDATE_ITEM_QUANTITY]: updateQuantity,
  [Types.FETCH_DISCOVERY]: getDiscovery,
  [Types.REMOVE_INTERNET_FROM_CART]: removeInternet,
  [Types.REMOVE_TV_FROM_CART]: removeTv,
  [Types.REMOVE_PHONE_FROM_CART]: removePhone,
  [Types.CART_PRODUCT]: setCartProduct
}

export default createReducer(INITIAL_STATE, HANDLERS)
