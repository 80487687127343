import { getExpirationDate, getTodayDate, isUpdateNeeded } from './dateTime'

export const loadState = (stateEntry) => {
  try {
    const serializedState = localStorage.getItem(stateEntry)
    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

export const saveState = (stateEntry, state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(stateEntry, serializedState)
  } catch (err) {
    return undefined
  }
}

export const clearState = () => {
  try {
    localStorage.clear()
  } catch (err) {
    return undefined
  }
}

export const getExpirationControlState = () => {
  const expirationControl = loadState('expirationControl')

  if (!expirationControl) {
    saveState('expirationControl', {
      today: getTodayDate(),
      expirationDate: getExpirationDate()
    })
    return {}
  }

  if (isUpdateNeeded(expirationControl.expirationDate, getTodayDate())) {
    clearState()

    saveState('expirationControl', {
      today: getTodayDate(),
      expirationDate: getExpirationDate()
    })

    return {}
  }

  return loadState('shoppingCart')
}
