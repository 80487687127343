import { Creators as AppCreators } from './app'
import { Creators as ProductsCreators } from './products'
import { Creators as CartCreators } from './cart'
import { Creators as AddressCreators } from './address'
import { Creators as UserCreators } from './user'
import { Creators as UtmCreators } from './utm'
import { Creators as InstallationCreators } from './installation'
import { Creators as InvoiceCreators } from './invoice'
import { Creators as PaymentCreators } from './payment'
import { Creators as ErrorCreators } from './error'
import { getBotScript } from '../../utils/botScript'
import { scrollHandler } from '../../utils/scrollToTop'
import { setLandingPage, setOriginURLSession } from '../../utils/sessionHandler'
import { parseStoreToCart } from '../../utils/checkout'
import { parseStateFromPosition } from '../../utils/states'
import { snakeCase } from 'lodash'

import {
  addProductToCartEvent,
  eventsToListen,
  removeProductFromCartEvent,
  triggerEvent
} from '../../utils/tracking'

import api from '../../services/api'
import {
  addItemQuantityFromArray,
  checkOptionalResources,
  excludeItemFromArray,
  filterProductFromCart,
  getCityCode,
  getProductById,
  getPropertyFromCart,
  removeItemQuantityFromArray
} from '../../utils/productHandlers'

const {
  setError
} = ErrorCreators

const {
  toggleLoadingResources,
  stopLoading,
  toggleChannelGrid,
  toggleChangeInternet,
  toggleChangeTV,
  toggleChangePhone,
  toggleChangeOptionals,
  loadChannelGrid,
  fetchBotMessage,
  fetchBotAnalyst,
  setBotScripts,
  setBotTips,
  setBotWarnings
} = AppCreators

const { fetchDate, fetchPeriod } = InstallationCreators

const {
  fetchDueDate,
  fetchBank,
  fetchAgency,
  fetchAccount,
  fetchDigit,
  fetchDirectDebit
} = PaymentCreators

const {
  fetchInvoiceEmail,
  fetchInvoiceZipCode,
  fetchInvoiceNeighborhood,
  fetchInvoiceCity,
  fetchInvoiceStreet,
  fetchInvoiceState,
  fetchInvoiceNumber,
  fetchInvoiceComplement,
  fetchElectronicInvoicing,
  fetchInvoiceAddress
} = InvoiceCreators

const {
  fetchProductsStart,
  fetchInternetSuccess,
  fetchTvSuccess,
  fetchPhoneSuccess,
  fetchComboSuccess,
  fetchProductsError
} = ProductsCreators

const {
  addInternetToCart,
  addTvToCart,
  addPhoneToCart,
  addComboToCart,
  removeInternetFromCart,
  removeTvFromCart,
  removePhoneFromCart,
  addOptionalTechnologies,
  addOptionalSpots,
  addOptionalProducts,
  removeOptionalTechnologies,
  removeOptionalSpots,
  removeOptionalProducts,
  updateItemQuantity,
  fetchDiscovery,
  cartProduct
} = CartCreators

const {
  fetchCep,
  fetchNumber,
  fetchComplement,
  fetchNeighborhood,
  fetchCity,
  fetchState,
  fetchStreet,
  checkWired,
  getAddress
} = AddressCreators

const {
  fetchPhone,
  fetchName,
  fetchCpf,
  fetchRg,
  fetchBirthday,
  fetchEmail
} = UserCreators

const {
  fetchUtm
} = UtmCreators

export const loadDefaultsThunk = scriptData => async (dispatch, getState) => {
  const pathname = getState().router.location.pathname
  const message = getBotScript(scriptData.scripts, pathname)
  const analystData = scriptData.analyst[0]
  const analyst = {
    name: analystData.name,
    avatarUrl: analystData.avatar.url
  }

  setOriginURLSession()
  setLandingPage()
  eventsToListen()

  await dispatch(setBotTips(scriptData.tips))
  await dispatch(setBotWarnings(scriptData.warnings))
  await dispatch(setBotScripts(scriptData.scripts))
  await dispatch(fetchBotMessage(message))
  await dispatch(fetchBotAnalyst(analyst))
}

const productHandle = {
  'internet': addInternetToCart,
  'tv': addTvToCart,
  'phone': addPhoneToCart
}

export const productsTypeList = ['internet', 'tv', 'phone']

export const addProductThunk = (id, type) => async (dispatch, getState) => {
  await dispatch(toggleLoadingResources())
  await dispatch(productHandle[type](id))

  const typeProducts = getState().products[type]
  const address = getState().address
  const product = getProductById(id, typeProducts)
  const cart = getState().cart
  const isCombo = verifyCombo(cart)

  await setCombo(dispatch, isCombo, cart, address)
  await setCartPrice(getState, dispatch)

  addProductToCartEvent(product)
  setCombo(dispatch, isCombo, cart, address)
  await dispatch(toggleLoadingResources())
  if (id === 0) {
    triggerEvent('doNotChooseProduct', {
      category: 'engagement',
      action: 'do not choose product',
      label: type
    })
  }
}

export const setCombo = async (dispatch, isCombo, cart, local) => {
  const { city, state } = local
  let comboId = isCombo ? createComboId(cart) : '0'

  if (isCombo) {
    const comboProduct = await api
      .getProductsById(comboId, snakeCase(city), state, 'combo')
      .then(response => response)
      .catch(err => dispatch(fetchProductsError(err)))
    if (!comboProduct.id) comboId = '0'
    await dispatch(fetchComboSuccess(comboProduct))
  }

  await dispatch(addComboToCart(comboId))
}

export const setCartPrice = async (getState, dispatch) => {
  const cart = getState().cart
  const products = getState().products
  const productType = cart.combo !== '0' ? 'combo' : discoverySingleProduct(cart)
  const productsByType = products[productType] || []
  const productId = cart[productType]
  const product = getProductById(productId, productsByType) || {}
  const productCart = Object.assign({}, product, { productType })
  dispatch(cartProduct(productCart))
}

const discoverySingleProduct = function (cart) {
  return Object.keys(cart).find((key) => {
    const item = cart[key]
    return item !== [] && item !== '0'
  })
}

export const createComboId = (cart) => {
  const comboId = `${cart.tv || 0}_${cart.internet || 0}_${cart.phone || 0}`
  return comboId
}

export const verifyCombo = (cart) => {
  const totalProducts = Object.keys(cart).reduce((totalProducts, product) => {
    if (productsTypeList.includes(product) && !!cart[product] && cart[product] !== '0') return totalProducts + 1
    return totalProducts
  }, 0)

  return totalProducts > 1
}

export const changeDate = date => async dispatch => {
  await dispatch(fetchDate(date))
}

export const changePeriod = period => async dispatch => {
  await dispatch(fetchPeriod(period))
}

export const changeDueDate = selectedDay => async dispatch => {
  await dispatch(fetchDueDate(parseInt(selectedDay)))
}

export const changeBank = bank => async dispatch => {
  await dispatch(fetchBank(bank))
}

export const changeAgency = agency => async dispatch => {
  return dispatch(fetchAgency(agency.replace(/\D/gi, '').substring(0, 7)))
}

export const changeAccount = account => async dispatch => {
  return dispatch(fetchAccount(account.replace(/\D/gi, '').substring(0, 12)))
}

export const changeDigit = digit => async dispatch => {
  return dispatch(fetchDigit(digit.replace(/\D/gi, '').substring(0, 2)))
}

export const changeDirectDebit = bool => async dispatch => {
  return dispatch(fetchDirectDebit(bool))
}

export const handleInvoiceEmailThunk = invoiceEmail => async dispatch => {
  await dispatch(fetchInvoiceEmail(invoiceEmail))
}

export const handleInvoiceZipCodeThunk = zipCode => async dispatch => {
  return dispatch(fetchInvoiceZipCode(zipCode.replace(/\D/gi, '').substring(0, 8)))
}

export const handleInvoiceNeighborhoodThunk = invoiceNeighborhood => async dispatch => {
  return dispatch(fetchInvoiceNeighborhood(invoiceNeighborhood))
}

export const handleInvoiceCityThunk = invoiceCity => async dispatch => {
  return dispatch(fetchInvoiceCity(invoiceCity))
}

export const handleInvoiceStreetThunk = invoiceStreet => async dispatch => {
  return dispatch(fetchInvoiceStreet(invoiceStreet))
}

export const handleInvoiceStateThunk = invoiceState => async dispatch => {
  return dispatch(fetchInvoiceState(parseStateFromPosition[invoiceState]))
}

export const handleInvoiceNumberThunk = invoiceNumber => async dispatch => {
  return dispatch(fetchInvoiceNumber(invoiceNumber))
}

export const handleInvoiceComplementThunk = invoiceComplement => async dispatch => {
  return dispatch(fetchInvoiceComplement(invoiceComplement))
}

export const handleElectronicInvoicingThunk = bool => async dispatch => {
  return dispatch(fetchElectronicInvoicing(bool))
}

export const handleInvoiceAddressThunk = bool => async dispatch => {
  return dispatch(fetchInvoiceAddress(bool))
}

export const handleNameThunk = value => {
  return async dispatch => {
    let name = value
    return dispatch(fetchName(name))
  }
}

export const handleRgThunk = rg => async dispatch => {
  return dispatch(fetchRg(rg))
}

export const handleBirthdayThunk = birthday => async dispatch => {
  return dispatch(fetchBirthday(birthday))
}

export const handleEmailThunk = email => async dispatch => {
  return dispatch(fetchEmail(email))
}

export const handleCpfThunk = cpf => async dispatch => {
  return dispatch(fetchCpf(cpf.replace(/\D/gi, '').substring(0, 11)))
}

export const handlePhoneThunk = value => async dispatch => {
  let phone = value

  phone = phone.replace(/\D/gi, '').substring(0, 11)

  return dispatch(fetchPhone(phone))
}

export const handleCepThunk = zipCode => async dispatch => {
  const code = zipCode.replace(/\D/gi, '').substring(0, 8)

  return dispatch(fetchCep({ zipCode: code }))
}

export const handleNumberThunk = number => async dispatch => {
  return dispatch(fetchNumber({ number: number.replace(/\D/gi, '') }))
}

export const handleComplementThunk = complement => async dispatch => {
  return dispatch(fetchComplement({ complement }))
}

export const handleNeighborhoodThunk = neighborhood => async dispatch => {
  return dispatch(fetchNeighborhood({ neighborhood }))
}

export const handleCityThunk = city => async dispatch => {
  return dispatch(fetchCity({ city }))
}

export const handleStateThunk = state => async dispatch => {
  return dispatch(fetchState({ state }))
}

export const handleStreetThunk = street => async dispatch => {
  return dispatch(fetchStreet({ street }))
}

export const handleAddressCheckoutThunk = zipCode => async dispatch => {
  const code = zipCode.replace(/\D/gi, '').substring(0, 8)

  if (code.length !== 8) {
    return dispatch(fetchCep({ zipCode: code }))
  }

  await dispatch(fetchCep({ zipCode: code }))

  const { bairro, localidade, logradouro, uf } = (await api.getAddressData(
    code
  )).data

  await dispatch(
    getAddress({
      street: logradouro,
      neighborhood: bairro,
      city: localidade,
      state: uf
    })
  )
}

export const handleAddressThunk = (history, productInfo = null) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(toggleLoadingResources())
    const zipCode = getState().address.zipCode
    const { wired, cidade } = (await api.getCepWired(zipCode)).data
    const { bairro, localidade, logradouro, uf } = (await api.getAddressData(
      zipCode
    )).data
    const cityCode = await getCityCode(cidade)

    await dispatch(
      getAddress({
        street: logradouro,
        neighborhood: bairro,
        city: localidade,
        state: uf,
        cityCode
      })
    )
    await dispatch(checkWired(wired))

    const isWired = getState().address.isWired

    triggerEvent('searchForWiring', {
      category: 'engagement',
      action: 'search for wiring',
      label: isWired ? 'available' : 'unavailable'
    })

    if (!isWired) {
      await dispatch(toggleLoadingResources())
      return history.push('/nao-cabeado/')
    }

    await dispatch(fetchProductsStart())

    const singleInternetPlans = await api
      .getProducsByCity(snakeCase(localidade), uf, 'internet')
      .then(response => response)
      .catch(err => dispatch(fetchProductsError(err)))

    const singleTvPlans = await api
      .getProducsByCity(snakeCase(localidade), uf, 'tv')
      .then(response => response)
      .catch(err => dispatch(fetchProductsError(err)))

    const singlePhonePlans = await api
      .getProducsByCity(snakeCase(localidade), uf, 'phone')
      .then(response => response)
      .catch(err => dispatch(fetchProductsError(err)))

    await dispatch(fetchInternetSuccess(singleInternetPlans))
    await dispatch(fetchTvSuccess(singleTvPlans))
    await dispatch(fetchPhoneSuccess(singlePhonePlans))

    let defaultRoute = '/internet/'
    const selectedProduct = productInfo
      ? singleInternetPlans.find(p => p.name === productInfo.name)
      : undefined

    if (selectedProduct) {
      defaultRoute = '/carrinho/'
      await dispatch(addProductThunk(selectedProduct.providerId, selectedProduct.kind))
    }

    await dispatch(toggleLoadingResources())
    await history.push(defaultRoute)
  } catch {
    dispatch(stopLoading())
    dispatch(setError(true, 'Falha na operação'))
  }
}

export const handleUtmThunk = utm => async dispatch =>
  dispatch(fetchUtm({ utm }))

export const handleContactThunk = userData => async dispatch =>
  api.saveContacts(userData)

export const updateBotMessageThunk = () => async (dispatch, getState) => {
  const pathname = getState().router.location.pathname
  const botScripts = getState().app.botScripts

  const message = getBotScript(botScripts, pathname)

  await dispatch(fetchBotMessage(message))
}

export const pageDefaultsThunk = history => async (dispatch, getState) => {
  return history.listen(async () => {
    scrollHandler()
    await dispatch(toggleLoadingResources())
    const pathname = history.location.pathname
    const botScripts = getState().app.botScripts

    const message = getBotScript(botScripts, pathname)

    await dispatch(fetchBotMessage(message))
    await dispatch(toggleLoadingResources())
  })
}

export const channelsThunk = (channels, packageName) => async (dispatch) => {
  triggerEvent('openChannelGrid', {
    category: 'engagement',
    action: 'open channel grid',
    label: packageName
  })

  await dispatch(loadChannelGrid(channels))
  await dispatch(toggleChannelGrid())
}

export const changeItemsThunk = pathname => {
  return async dispatch => {
    switch (pathname) {
      case '/internet/':
        await dispatch(toggleChangeInternet())
        break

      case '/telefone/':
        await dispatch(toggleChangePhone())
        break

      case '/tv/':
        await dispatch(toggleChangeTV())
        break

      case '/produtos-adicionais/':
        await dispatch(toggleChangeOptionals())
        break

      default:
        break
    }
  }
}

export const createOrderThunk = history => async (dispatch, getState) => {
  await dispatch(toggleLoadingResources())
  let order = parseStoreToCart(getState())

  await api.createOrder(order)
  await dispatch(toggleLoadingResources())

  triggerEvent('createOrder', {
    category: 'conversion',
    action: 'create order',
    label: `NET/Claro - ${(order.product || {}).kind}`
  })

  return history.push('/obrigado/')
}

export const removeProductThunk = id => async (dispatch, getState) => {
  const cart = getState().cart
  const itemToExclude = filterProductFromCart(id, cart)
  const excludeProperty = Object.keys(itemToExclude).toString()

  if (
    excludeProperty === 'internet' ||
    excludeProperty === 'phone' ||
    excludeProperty === 'tv'
  ) {
    const productStore = getState().products[excludeProperty]
    const product = getProductById(id, productStore)
    removeProductFromCartEvent(product)
  }

  switch (excludeProperty) {
    case 'internet':
      await dispatch(removeInternetFromCart())
      break

    case 'phone':
      await dispatch(removePhoneFromCart())
      break

    case 'tv':
      await dispatch(removeTvFromCart())
      break

    case 'optionalSpots':
      await dispatch(removeOptionalSpots(itemToExclude[excludeProperty]))
      break

    case 'optionalTechnologies':
      await dispatch(removeOptionalTechnologies(itemToExclude[excludeProperty]))
      break

    case 'optionalProducts':
      await dispatch(removeOptionalProducts(itemToExclude[excludeProperty]))
      break

    default:
      break
  }

  const cityCode = getState().address.cityCode
  const cartStore = getState().cart

  await dispatch(toggleLoadingResources())
  const discovery = await api.getDiscoveryByCity(cityCode, cartStore)

  await dispatch(fetchDiscovery(discovery.products ? discovery : {}))
  await dispatch(toggleLoadingResources())
}

export const toggleOptionalsThunk = (event, id) => {
  event.stopPropagation()

  return async (dispatch, getState) => {
    const cart = getState().cart
    const discovery = getState().cart.discovery
    const isChecked = checkOptionalResources(id, cart)
    const selectedProperty = getPropertyFromCart(id, discovery)

    if (selectedProperty === 'optionalSpots') {
      if (!isChecked) {
        return dispatch(addOptionalSpots({ id, quantity: 1 }))
      }

      const itemsNotExcluded = excludeItemFromArray(id, selectedProperty, cart)
      return dispatch(removeOptionalSpots([...itemsNotExcluded]))
    }

    if (selectedProperty === 'optionalTechnologies') {
      if (!isChecked) {
        return dispatch(addOptionalTechnologies({ id, quantity: 1 }))
      }

      const itemsNotExcluded = excludeItemFromArray(id, selectedProperty, cart)
      return dispatch(removeOptionalTechnologies([...itemsNotExcluded]))
    }

    if (selectedProperty === 'optionalProducts') {
      if (!isChecked) {
        return dispatch(addOptionalProducts({ id, quantity: 1 }))
      }

      const itemsNotExcluded = excludeItemFromArray(id, selectedProperty, cart)
      return dispatch(removeOptionalProducts([...itemsNotExcluded]))
    }
  }
}

export const addQuantityThunk = (event, id) => {
  event.stopPropagation()

  return async (dispatch, getState) => {
    const cart = getState().cart
    const discovery = getState().cart.discovery
    const isChecked = checkOptionalResources(id, cart)
    const selectedProperty = getPropertyFromCart(id, discovery)

    if (selectedProperty === 'optionalSpots') {
      if (!isChecked) {
        return dispatch(addOptionalSpots({ id, quantity: 1 }))
      }

      const updatedItems = addItemQuantityFromArray(id, selectedProperty, cart)
      return dispatch(updateItemQuantity([...updatedItems]))
    }
  }
}

export const removeQuantityThunk = (event, id) => {
  event.stopPropagation()

  return async (dispatch, getState) => {
    const cart = getState().cart
    const discovery = getState().cart.discovery
    const isChecked = checkOptionalResources(id, cart)
    const selectedProperty = getPropertyFromCart(id, discovery)

    if (selectedProperty === 'optionalSpots') {
      if (!isChecked) {
        return dispatch(addOptionalSpots({ id, quantity: 1 }))
      }

      const updatedItems = removeItemQuantityFromArray(
        id,
        selectedProperty,
        cart
      )
      return dispatch(updateItemQuantity([...updatedItems]))
    }
  }
}
