import React from 'react'
import { Link } from 'react-router-dom'
import { Cookies, withCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import Ink from 'react-ink'

import Brand from '../../components/Brand'
import { gaCookieParser } from '../../utils/gaCookieParser'
import { getUrlParameter } from '../../utils/getUrlParameter'
import './Header.scss'

import ZelasHeader from '../../components/ZelasLayout/Header'
import { isZelasTheme } from '../../utils/getTheme'

const renderPaidCampaignButton = allCookies => {
  const gaCookies = gaCookieParser(allCookies)
  const hasCookie = gaCookies.utmz && gaCookies.utmz.utmcmd === 'cpc'

  if (hasCookie || getUrlParameter('utm_medium') === 'cpc') {
    return (
      <a
        href='https://assine.net.com.br/?canalDeVenda=escale&amp;utm_source=escale&amp;utm_medium=aa&amp;utm_campaign=barra-topo'
        target='_blank'
        rel='noopener noreferrer'
        className='header__cta'
      >
        Monte seu Combo
        <Ink />
      </a>
    )
  }

  return ''
}

const Header = ({ cookies }) => {
  const allCookies = cookies.getAll()

  if (isZelasTheme()) {
    return <ZelasHeader />
  }

  return (
    <header className='header'>
      <div className='app__container'>
        <div className='header__content'>
          <Link to='/'>
            <Brand className='header__brand' alt='logo' />
          </Link>
          {renderPaidCampaignButton(allCookies)}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired
}

export default withCookies(Header)
