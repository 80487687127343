import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import history from './history'

import App from '../containers/App'

import Home from '../pages/Home'
import Internet from '../pages/Internet'
import Tv from '../pages/Tv'
import Phone from '../pages/Phone'
import OptionalFeatures from '../pages/OptionalFeatures'
import NotWired from '../pages/NotWired'
import Cart from '../pages/Cart'
import Checkout from '../pages/Checkout'
import Payment from '../pages/Payment'
import Invoice from '../pages/Invoice'
import ThankYou from '../pages/ThankYou'

const Routes = () => (
  <ConnectedRouter history={history}>
    <App history={history}>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/internet/' component={Internet} />
        <Route path='/tv/' component={Tv} />
        <Route path='/telefone/' component={Phone} />
        <Route path='/produtos-adicionais/' component={OptionalFeatures} />
        <Route path='/nao-cabeado/' component={NotWired} />
        <Route path='/carrinho/' component={Cart} />
        <Route path='/checkout/' component={Checkout} />
        <Route path='/pagamento/' component={Payment} />
        <Route path='/fatura/' component={Invoice} />
        <Route path='/obrigado/' component={ThankYou} />
      </Switch>
    </App>
  </ConnectedRouter>
)

export default Routes
