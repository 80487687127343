import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RichText } from 'prismic-reactjs'

import { ReactComponent as TicketSVG } from './ticket.svg'
import { ReactComponent as AccountDebitSVG } from './account_debit.svg'
import {
  changeDueDate,
  changeBank,
  changeAgency,
  changeAccount,
  changeDigit,
  changeDirectDebit,
  createOrderThunk
} from '../../store/ducks/thunks'
import { getBotTip, getBotWarning } from '../../utils/botScript'
import Message from '../../components/Message'
import { Input, Select } from '../../components/Inputs'
import BoxCard from '../../components/BoxCard'
import CtaButton from '../../components/Button/CtaButton'
import banks from './banks.json'
import FormHandler from '../../components/FormHandler'
import validationSchema from './paymentValidation'

function Payment (props) {
  const {
    analystName,
    avatarUrl,
    history,
    dueDate,
    bank,
    agency,
    account,
    digit,
    directDebit,
    botTips,
    botWarnings
  } = props
  const paymentWarning = getBotTip(botTips, 'paymentWarning').text
  const safeSiteNotice = getBotWarning(botWarnings, 'notice')
  const safetyDisclaimer = getBotWarning(botWarnings, 'disclaimer')

  const initialValues = {
    paymentType: directDebit ? 'debito' : 'boleto',
    dueDate: dueDate || '',
    bank: bank.code ? JSON.stringify(bank) : '',
    agency,
    account,
    digit
  }

  const handleSubmit = values => {
    props.handleDate(values.dueDate)
    props.handleBank(values.bank ? JSON.parse(values.bank) : {})
    props.handleAgency(values.agency)
    props.handleAccount(values.account)
    props.handleDigit(values.digit)
    props.handleDirectDebit(values.paymentType === 'debito')

    props.createOrderConnected(history)
  }

  return (
    <Fragment>
      <FormHandler
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <PaymentForm
          {...props}
          safetyDisclaimer={safetyDisclaimer}
          analystName={analystName}
          avatarUrl={avatarUrl}
          safeSiteNotice={safeSiteNotice}
          paymentWarning={paymentWarning}
        />
      </FormHandler>
    </Fragment>
  )
}

const paymentType = [
  { name: 'boleto', text: 'Boleto', icon: <TicketSVG /> },
  { name: 'debito', text: 'Débito em conta', icon: <AccountDebitSVG /> }
]

const bankFields = [
  { name: 'agency', label: 'Agência' },
  { name: 'account', label: 'Conta corrente' },
  { name: 'digit', label: 'Dígito' }
]

const PaymentForm = ({
  values,
  handleChange, handleBlur,
  touched, errors, setFieldValue,
  isSubmitting,
  safetyDisclaimer,
  analystName,
  avatarUrl,
  safeSiteNotice,
  paymentWarning
}) =>
  <Fragment>
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
      {paymentType.map((item, index) =>
        <BoxCard
          key={index}
          name={item.name}
          text={item.text}
          icon={item.icon}
          onClick={() => setFieldValue('paymentType', item.name)}
          selected={values.paymentType === item.name}
          radio
        />
      )}
    </div>

    <Select
      name='dueDate'
      onChange={handleChange}
      onBlur={handleBlur}
      value={values.dueDate}
      label='Dia do vencimento'
      error={touched.dueDate && errors.dueDate}
    >
      <option value='' disabled>Escolha o dia</option>
      {['5', '8', '10', '15', '20'].map(day =>
        <option value={day} key={day}>{day}</option>
      )}
    </Select>
    {values.paymentType === 'debito' && (
      <Fragment>
        {paymentWarning && (
          <Message
            name={analystName}
            avatarUrl={avatarUrl}
            message={RichText.render(paymentWarning)}
          />
        )}
        <div className='row'>
          <div className='column'>
            <Select
              name='bank'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.bank}
              label='Banco'
              error={touched.bank && errors.bank}
            >
              <option value='' disabled>Selecione um banco...</option>
              {Object.entries(banks).map(([code, name]) =>
                <option value={JSON.stringify({ name, code })} key={code}>{name}</option>
              )}
            </Select>
          </div>
          {bankFields.map((item, key) =>
            <div className='column' key={key}>
              <Input
                type='tel'
                name={item.name}
                onChange={handleChange}
                onBlur={handleBlur}
                label={item.label}
                error={touched[item.name] && errors[item.name]}
              />
            </div>
          )}
        </div>
      </Fragment>
    )}
    {safetyDisclaimer && (
      <Message
        name={analystName}
        avatarUrl={avatarUrl}
        message={RichText.render(safetyDisclaimer)}
      />
    )}
    {safeSiteNotice && (
      <Message
        name={analystName}
        message={RichText.render(safeSiteNotice)}
        secondary
      />
    )}

    <CtaButton
      type='submit'
      text='Finalizar'
      isLoading={isSubmitting}
      isDisabled={isSubmitting}
    />
  </Fragment>

Payment.propTypes = {
  analystName: PropTypes.string,
  avatarUrl: PropTypes.string,
  bank: PropTypes.object,
  agency: PropTypes.string,
  account: PropTypes.string,
  digit: PropTypes.string,
  createOrderConnected: PropTypes.func,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  botTips: PropTypes.arrayOf(PropTypes.object),
  botWarnings: PropTypes.arrayOf(PropTypes.object)
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createOrderConnected: createOrderThunk,
      handleDate: changeDueDate,
      handleBank: changeBank,
      handleAccount: changeAccount,
      handleAgency: changeAgency,
      handleDigit: changeDigit,
      handleDirectDebit: changeDirectDebit
    },
    dispatch
  )

const mapStateToProps = store => ({
  analystName: store.app.analyst.name,
  avatarUrl: store.app.analyst.avatarUrl,
  dueDate: store.payment.dueDate,
  bank: store.payment.bank,
  agency: store.payment.agency,
  account: store.payment.account,
  digit: store.payment.digit,
  directDebit: store.payment.directDebit,
  isLoading: store.app.isLoading,
  botTips: store.app.botTips,
  botWarnings: store.app.botWarnings
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment)
