import React from 'react'
import { Formik } from 'formik'

const FormHandler = ({ children, validationSchema, defaultSubmission, handleSubmit, initialValues }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit || defaultSubmission}
    >{({
        values,
        touched,
        errors,
        handleChange,
        validateForm,
        handleBlur,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        isValid
      }) => {
        const childrenWithProps = React.Children.map(children, child =>
          React.cloneElement(child, { values, touched, errors, handleChange, handleBlur, validateForm, setFieldValue, isSubmitting, isValid })
        )
        return (
          <form onSubmit={handleSubmit}>
            {childrenWithProps}
          </form>
        )
      }}
    </Formik>

  )
}

export default FormHandler
