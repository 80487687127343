import moment from 'moment'

const parseState = state => {
  let fromTo = {
    Acre: 'AC',
    Alagoas: 'AL',
    Amapá: 'AP',
    Amazonas: 'AM',
    Bahia: 'BA',
    Ceará: 'CE',
    'Distrito Federal': 'DF',
    'Espírito Santo': 'ES',
    Goiás: 'GO',
    Maranhão: 'MA',
    'Mato Grosso': 'MT',
    'Mato Grosso do Sul': 'MS',
    'Minas Gerais': 'MG',
    Pará: 'PA',
    Paraíba: 'PB',
    Paraná: 'PR',
    Pernambuco: 'PE',
    Piauí: 'PI',
    'Rio de Janeiro': 'RJ',
    'Rio Grande do Norte': 'RN',
    'Rio Grande do Sul': 'RS',
    Rondônia: 'RO',
    Roraima: 'RR',
    'Santa Catarina': 'SC',
    'São Paulo': 'SP',
    Sergipe: 'SE',
    Tocantins: 'TO'
  }

  return fromTo[state] || state
}

const getArea = phone => {
  return phone.substring(0, 2)
}

const getPhone = phone => {
  return phone.substring(2)
}

const formatDate = date => {
  const convertDate = moment(date, 'DD/MM/YYYY')
  return convertDate.format('YYYY-MM-DD')
}

export const parseStoreToCart = ({
  user = {},
  address = {},
  cart = {},
  invoice = {},
  installation = {},
  payment = {},
  utm = {}
}) => {
  const paymentInput = Object.assign({}, {
    electronicInvoicing: invoice.electronicInvoicing,
    invoiceEmail: invoice.email,
    paymentDay: payment.dueDate,
    directDebit: payment.directDebit,
    debitDetails: {
      bankNumber: payment.bank.code || '',
      agencyNumber: payment.agency || '',
      accountNumber: payment.account || '',
      accountDigit: payment.digit || ''
    }
  },
  !invoice.electronicInvoicing && invoice.invoiceAddress && {
    billingAddress: {
      address: invoice.street || address.street,
      number: invoice.number || address.number,
      district: invoice.neighborhood || address.neighborhood,
      complement: invoice.complement || address.complement,
      city: invoice.city || address.city,
      state: invoice.state || parseState(address.state),
      zipcode: invoice.zipCode || address.zipCode
    }
  })
  const orderInput = Object.assign({},
    {
      order: {
        schedule: [
          {
            date: moment(installation.firstDate).format('YYYY-MM-DD'),
            period: parseInt(installation.firstDatePeriod)
          },
          {
            date: moment(installation.secondDate).format('YYYY-MM-DD'),
            period: parseInt(installation.secondDatePeriod)
          }
        ],
        installationAddress: {
          address: address.street,
          number: address.number,
          city: address.city,
          district: address.neighborhood,
          complement: address.complement || 'N/D',
          state: parseState(address.state),
          zipcode: address.zipCode
        },
        provider: 'claro',
        product: {
          id: cart.cartProduct.providerId || {},
          kind: cart.cartProduct.productType
        },
        payment: paymentInput
      }
    }
  )

  const userInput = Object.assign({}, {
    user: {
      name: user.name,
      email: user.email,
      birthdate: formatDate(user.birthday),
      cpf: user.cpf,
      rg: user.rg,
      phone: {
        area: getArea(user.phone),
        number: getPhone(user.phone)
      }
    }
  })

  const trackingInput = Object.assign({}, {
    tracking: {
      medium: utm.utmcmd || '',
      source: utm.utmcsr || '',
      campaign: utm.utmccn || '',
      landingPage: utm.landingPage || '',
      device: utm.device || ''
    }
  })

  const checkoutOrder = Object.assign({}, userInput, orderInput, trackingInput)

  return checkoutOrder
}
