import moment from 'moment'

const today = moment(new Date())
const tomorrow = moment(new Date()).add(1, 'days')

export const getExpirationDate = () => tomorrow
export const getTodayDate = () => today

export const isUpdateNeeded = (storeDate, todayDate) => {
  if (!storeDate) {
    return true
  }

  const duration = moment.duration(moment(storeDate).diff(todayDate))
  const hours = duration.asHours()

  if (hours <= 0) {
    return true
  }

  return false
}
