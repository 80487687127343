import React from 'react'

import { Input, Select } from '../../components/Inputs'
import { inputMask } from '../../utils/formMasks'
import states from '../../utils/states.json'
import cities from '../../utils/cities.json'

const AddressForm = ({ values, touched, errors, handleChange, handleBlur, blockEdit }) =>
  <div className='form'>
    <Input
      type='tel'
      name='zipCode'
      onChange={handleChange}
      onBlur={handleBlur}
      value={inputMask('cep', values.zipCode)}
      label='CEP'
      error={touched.zipCode && errors.zipCode}
      disabled={!!blockEdit}
    />
    <Input
      type='text'
      name='neighborhood'
      value={values.neighborhood}
      onChange={handleChange}
      onBlur={handleBlur}
      label='Bairro'
      error={touched.neighborhood && errors.neighborhood}
      disabled={values.neighborhood ? !!blockEdit : false}
    />
    <div className='twoColumns'>
      <Select
        name='state'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.state}
        label={'Estado'}
        error={touched.state && errors.state}
        disabled={!!blockEdit}
      >
        <option value='' disabled>Escolha o estado</option>
        {Object.entries(states).map(([code, name]) =>
          <option value={code} key={code}>{name}</option>
        )}
      </Select>

      <Select
        name='city'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.city}
        label='Cidade'
        error={touched.city && errors.city}
        disabled={!values.state || !!blockEdit}
      >
        <option value='' disabled>Escolha a cidade</option>
        {values.state && Object.entries(cities[values.state].cidades).map(([code, name]) =>
          <option value={code} key={code}>{name}</option>
        )}
      </Select>
    </div>
    <Input
      name='street'
      type='text'
      value={values.street}
      onChange={handleChange}
      onBlur={handleBlur}
      label='Endereço'
      error={touched.street && errors.street}
      disabled={values.neighborhood ? !!blockEdit : false}
    />
    <div className='twoColumns'>
      <Input
        name='number'
        type='text'
        value={values.number}
        onChange={handleChange}
        onBlur={handleBlur}
        label='Número'
        error={touched.number && errors.number}
      />
      <Input
        name='complement'
        type='text'
        value={values.complement}
        onChange={handleChange}
        onBlur={handleBlur}
        label='Complemento'
        error={touched.complement && errors.complement}
      />
    </div>
  </div>

export default AddressForm
