import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment'

import {
  changeDate,
  changePeriod,
  handleInvoiceEmailThunk,
  handleInvoiceZipCodeThunk,
  handleInvoiceNeighborhoodThunk,
  handleInvoiceCityThunk,
  handleInvoiceStateThunk,
  handleInvoiceStreetThunk,
  handleInvoiceNumberThunk,
  handleInvoiceComplementThunk,
  handleElectronicInvoicingThunk,
  handleInvoiceAddressThunk
} from '../../store/ducks/thunks'
import CtaButton from '../../components/Button/CtaButton'
import Message from '../../components/Message'
import { Input, Check, DateInput } from '../../components/Inputs'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { getBotTip } from '../../utils/botScript'
import BoxCard from '../../components/BoxCard'
import { FaEnvelope, FaHome } from 'react-icons/fa'
import FormHandler from '../../components/FormHandler'
import AddressForm from '../../containers/AddressForm'
import validationSchema from './invoiceValidation'

import 'moment/locale/pt-br'
import 'react-day-picker/lib/style.css'
import './Invoice.scss'

const format = 'DD/MM/YYYY'
const startDate = new Date()
const endDate = new Date()
startDate.setDate(startDate.getDate() + 1)
endDate.setDate(endDate.getDate() + 8)

const Invoice = props => {
  const {
    electronicInvoicing,
    invoiceAddress,
    firstDate,
    firstDatePeriod,
    secondDate,
    secondDatePeriod,
    email,
    zipCode,
    neighborhood,
    city,
    state,
    street,
    number,
    complement,
    botTips
  } = props

  const stepMessage = getBotTip(botTips, 'invoiceStep').text
  const currentAddress = getBotTip(botTips, 'currentAddress').text
  const selectInstallationDate = getBotTip(botTips, 'selectInstallationDate').text

  const invoice = [
    { type: 'bill', value: 'Por e-mail', icon: <FaEnvelope /> },
    { type: 'bill', value: 'Por correio', icon: <FaHome /> }
  ]

  const initialValues = {
    invoiceType: electronicInvoicing ? 'Por e-mail' : 'Por correio',
    email,
    invoiceAddress,
    zipCode,
    neighborhood,
    city,
    state,
    street,
    number,
    complement,
    firstDate,
    firstDatePeriod,
    secondDate,
    secondDatePeriod
  }

  const handleSubmit = values => {
    props.handleElectronicInvoicingConnected(values.invoiceType === 'Por e-mail')
    props.handleInvoiceEmailConnected(values.email)
    props.handleDate({ firstDate: values.firstDate, secondDate: values.secondDate })
    props.handlePeriod({ firstDatePeriod: values.firstDatePeriod, secondDatePeriod: values.secondDatePeriod })
    props.handleInvoiceZipCodeConnected(values.zipCode)
    props.handleInvoiceNeighborhoodConnected(values.neighborhood)
    props.handleInvoiceCityConnected(values.city)
    props.handleInvoiceStateConnected(values.state)
    props.handleInvoiceStreetConnected(values.street)
    props.handleInvoiceNumberConnected(values.number)
    props.handleInvoiceComplementConnected(values.complement)
    props.handleInvoiceAddressConnected(values.invoiceAddress)
    props.history.push('/pagamento/')
  }

  return (
    <Fragment>
      <FormHandler
        validationSchema={validationSchema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        <InvoiceForm
          {...props}
          invoice={invoice}
          currentAddress={currentAddress}
          stepMessage={stepMessage}
          selectInstallationDate={selectInstallationDate}
        />
      </FormHandler>
    </Fragment>
  )
}

const InvoiceForm = ({
  values,
  touched, errors,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  invoice,
  analystName,
  avatarUrl,
  currentAddress,
  stepMessage,
  selectInstallationDate
}) =>
  <Fragment>
    <div className='InvoiceType'>
      {invoice.map((item, i) =>
        <BoxCard
          key={i}
          name={item.value}
          text={item.value}
          icon={item.icon}
          onClick={() => setFieldValue('invoiceType', item.value)}
          selected={values.invoiceType === item.value}
          radio
        />
      )}
    </div>
    {values.invoiceType === 'Por e-mail' &&
    <div className='form'>
      <Input
        type='email'
        name='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        label='E-mail de cobrança'
        error={touched.email && errors.email}
      />
    </div>}
    {values.invoiceType === 'Por correio' && (
      <React.Fragment>
        <Message
          name={analystName}
          avatarUrl={avatarUrl}
          message={RichText.render(currentAddress)}
        />
        <Check
          type='checkbox'
          name='invoiceAddress'
          onChange={handleChange}
          checked={values.invoiceAddress}
          label='O meu endereco de cobrança é diferente do endereço de instalação.'
          error={touched.invoiceAddress && errors.invoiceAddress}
        />
      </React.Fragment>
    )}

    {values.invoiceType === 'Por correio' && values.invoiceAddress && (
      <AddressForm
        values={values}
        touched={touched}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    )}

    {values.invoiceType && (
      <Fragment>
        <Message
          name={analystName}
          avatarUrl={avatarUrl}
          message={RichText.render(selectInstallationDate)}
        />
        <DateInput
          placeholder='Escolha o dia'
          name='firstDate'
          onDayChange={date => setFieldValue('firstDate', date)}
          inputProps={{ className: `input__text ${touched.firstDate && errors.firstDate ? 'error' : ''}` }}
          value={values.firstDate}
          formatDate={formatDate}
          format={format}
          dayPickerProps={{
            disabledDays: [
              values.secondDate,
              { after: endDate, before: startDate },
              { daysOfWeek: [0] }
            ],
            localeUtils: MomentLocaleUtils,
            locale: 'pt-br'
          }}
          label='Data 1'
          error={(touched.firstDate && errors.firstDate) ? errors.firstDate : ''}
        />

        {values.firstDate && (
          <Fragment>
            {['Manhã', 'Tarde'].map((item, key) =>
              <Check
                type='radio'
                name='firstDatePeriod'
                value={key + 1}
                key={key}
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.firstDatePeriod === String(key + 1)}
                label={item}
                error={
                  (key > 0 && touched.firstDatePeriod && errors.firstDatePeriod)
                    ? errors.firstDatePeriod
                    : ''
                }
              />
            )}
          </Fragment>
        )}
        <DateInput
          placeholder='Escolha o dia'
          onDayChange={date => setFieldValue('secondDate', date)}
          inputProps={{ className: `input__text ${touched.secondDate && errors.secondDate ? 'error' : ''}` }}
          value={values.secondDate}
          formatDate={formatDate}
          format={format}
          dayPickerProps={{
            disabledDays: [
              values.firstDate,
              { after: endDate, before: startDate },
              { daysOfWeek: [0] }
            ],
            localeUtils: MomentLocaleUtils,
            locale: 'pt-br'
          }}
          label='Data 2'
          error={touched.secondDate && errors.secondDate}
        />

        {values.secondDate && (
          <Fragment>
            {['Manhã', 'Tarde'].map((item, key) =>
              <Check
                type='radio'
                name='secondDatePeriod'
                value={key + 1}
                key={key}
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.secondDatePeriod === String(key + 1)}
                label={item}
                error={
                  (key > 0 && touched.secondDatePeriod && errors.secondDatePeriod)
                    ? errors.secondDatePeriod
                    : ''
                }
              />
            )}
          </Fragment>
        )}
        <Message
          name={analystName}
          avatarUrl={avatarUrl}
          message={RichText.render(stepMessage)}
        />
        <CtaButton
          type='submit'
          text='Próximo passo'
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
        />
      </Fragment>
    )}
  </Fragment>

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    handleInvoiceZipCodeConnected: handleInvoiceZipCodeThunk,
    handleDate: changeDate,
    handlePeriod: changePeriod,
    handleInvoiceEmailConnected: handleInvoiceEmailThunk,
    handleInvoiceNeighborhoodConnected: handleInvoiceNeighborhoodThunk,
    handleInvoiceCityConnected: handleInvoiceCityThunk,
    handleInvoiceStateConnected: handleInvoiceStateThunk,
    handleInvoiceStreetConnected: handleInvoiceStreetThunk,
    handleInvoiceNumberConnected: handleInvoiceNumberThunk,
    handleInvoiceComplementConnected: handleInvoiceComplementThunk,
    handleElectronicInvoicingConnected: handleElectronicInvoicingThunk,
    handleInvoiceAddressConnected: handleInvoiceAddressThunk
  }, dispatch)

Invoice.propTypes = {
  firstDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  firstDatePeriod: PropTypes.string,
  secondDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  secondDatePeriod: PropTypes.string,
  analystName: PropTypes.string,
  avatarUrl: PropTypes.string,
  handleDate: PropTypes.func,
  handlePeriod: PropTypes.func,
  history: PropTypes.object,
  handleInvoiceEmailConnected: PropTypes.func,
  email: PropTypes.string,
  handleInvoiceZipCodeConnected: PropTypes.func,
  zipCode: PropTypes.string,
  handleInvoiceNeighborhoodConnected: PropTypes.func,
  neighborhood: PropTypes.string,
  handleInvoiceCityConnected: PropTypes.func,
  city: PropTypes.string,
  handleInvoiceStateConnected: PropTypes.func,
  state: PropTypes.string,
  handleInvoiceStreetConnected: PropTypes.func,
  street: PropTypes.string,
  handleInvoiceNumberConnected: PropTypes.func,
  number: PropTypes.string,
  handleInvoiceComplementConnected: PropTypes.func,
  complement: PropTypes.string,
  isLoading: PropTypes.bool,
  botTips: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = store => ({
  firstDate: store.installation.firstDate,
  secondDate: store.installation.secondDate,
  firstDatePeriod: store.installation.firstDatePeriod,
  secondDatePeriod: store.installation.secondDatePeriod,
  analystName: store.app.analyst.name,
  avatarUrl: store.app.analyst.avatarUrl,
  email: store.invoice.email,
  zipCode: store.invoice.zipCode,
  neighborhood: store.invoice.neighborhood,
  city: store.invoice.city,
  state: store.invoice.state,
  invoiceAddress: store.invoice.invoiceAddress,
  street: store.invoice.street,
  number: store.invoice.number,
  complement: store.invoice.complement,
  electronicInvoicing: store.invoice.electronicInvoicing,
  isLoading: store.app.isLoading,
  botTips: store.app.botTips
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invoice)
