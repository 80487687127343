import React from 'react'
import PropTypes from 'prop-types'
import { FaArrowLeft, FaMinus, FaPlus, FaTimes } from 'react-icons/fa'
import Button from './index'

export const BackButton = ({ children, ...props }) => (
  <Button {...props}>
    <FaArrowLeft />
    {children}
  </Button>
)

BackButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

BackButton.defaultProps = {
  className: '',
  children: ''
}

export const PlusButton = ({ children, ...props }) => (
  <Button {...props}>
    <FaPlus />
    {children}
  </Button>
)

PlusButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

PlusButton.defaultProps = {
  className: '',
  children: ''
}

export const MinusButton = ({ children, ...props }) => (
  <Button {...props}>
    <FaMinus />
    {children}
  </Button>
)

MinusButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

MinusButton.defaultProps = {
  className: '',
  children: ''
}

export const CloseButton = ({ children, ...props }) => (
  <Button {...props}>
    <FaTimes />
    {children}
  </Button>
)

CloseButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

CloseButton.defaultProps = {
  className: '',
  children: ''
}
