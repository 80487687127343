import React from 'react'
import PropTypes from 'prop-types'
import AnimatedTyping from '../AnimatedTyping'
import Avatar from '../Avatar'
import './BotWithMessage.scss'

const BotWithMessage = ({
  name,
  text,
  avatarUrl,
  isLoading
}) => (
  <div className='botWithMessage'>
    <Avatar name={name} avatarUrl={avatarUrl} />
    <strong className='botWithMessage__title'>{name}</strong>

    {!isLoading
      ? (
        <div className='botWithMessage__message'>
          <div className='botWithMessage__arrowUp' />
          <AnimatedTyping message={text} />
        </div>
      ) : (
        <div className='botWithMessage__message'>
          <div className='botWithMessage__arrowUp' />
          <AnimatedTyping message={text} />
        </div>
      )}
  </div>
)

BotWithMessage.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  avatarUrl: PropTypes.string,
  isLoading: PropTypes.bool
}

BotWithMessage.defaultProps = {
  name: '',
  text: '',
  avatarUrl: '',
  isLoading: false
}

export default BotWithMessage
