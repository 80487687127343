import React from 'react'
import PropTypes from 'prop-types'
import { FaCheck } from 'react-icons/fa'
import { isZelasTheme } from '../../utils/getTheme'
import Button from './index'
import './Button.scss'

export const CtaButton = ({
  text,
  type,
  isLoading,
  isSuccess,
  isDisabled,
  onClickHandler
}) => (
  <Button
    type={type}
    className={`ctaButton ${isZelasTheme() ? 'zelasCtaButton' : ''}`}
    isDisabled={isDisabled}
    onClickHandler={onClickHandler}
  >
    {!isLoading
      ? text
      : (<span className={`ctaButton__loading  ${isZelasTheme() ? 'zelasCtaButton__loading' : ''}`} />)
    }

    {isSuccess && (<FaCheck />)}
  </Button>
)

CtaButton.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool
}

CtaButton.defaultProps = {
  text: 'Próximo passo',
  isLoading: false,
  hasSubimited: false,
  isDisabled: false
}

export default CtaButton
