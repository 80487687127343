import React from 'react'
import PropTypes from 'prop-types'
import './Avatar.scss'

const Avatar = ({ name, avatarUrl }) => (
  <div className='avatar'>
    <img className='avatar__image' src={avatarUrl} alt={`Analista ${name}`} />
  </div>
)

Avatar.propTypes = {
  name: PropTypes.string,
  avatarUrl: PropTypes.string
}

Avatar.defaultProps = {
  name: '',
  avatarUrl: ''
}

export default Avatar
