import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  fetchProductsStart: [''],
  fetchInternetSuccess: ['internetProducts'],
  fetchComboSuccess: ['comboProducts'],
  fetchTvSuccess: ['tvProducts'],
  fetchPhoneSuccess: ['phoneProducts'],
  fetchChannelsSuccess: ['channels'],
  fetchProductsError: ['error']
})

const INITIAL_STATE = {
  error: null,
  internet: [],
  tv: [],
  phone: [],
  channels: [],
  combo: []
}

const start = (state = INITIAL_STATE) => ({
  ...state,
  error: null
})

const internetOnSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  internet: action.internetProducts
})

const comboOnSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  combo: [action.comboProducts]
})

const tvOnSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  tv: action.tvProducts
})

const phoneOnSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  phone: action.phoneProducts
})

const channelsOnSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: null,
  channels: action.channels
})

const error = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error
})

export const HANDLERS = {
  [Types.FETCH_PRODUCTS_START]: start,
  [Types.FETCH_INTERNET_SUCCESS]: internetOnSuccess,
  [Types.FETCH_COMBO_SUCCESS]: comboOnSuccess,
  [Types.FETCH_TV_SUCCESS]: tvOnSuccess,
  [Types.FETCH_PHONE_SUCCESS]: phoneOnSuccess,
  [Types.FETCH_CHANNELS_SUCCESS]: channelsOnSuccess,
  [Types.FETCH_PRODUCTS_ERROR]: error
}

export default createReducer(INITIAL_STATE, HANDLERS)
