import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Creators as CartCreators } from '../../store/ducks/app'

import Header from '../Header'
import HeaderNavigation from '../Header/HeaderNavigation'
import ChannelGrid from '../ChannelGrid'
import Internet from '../../pages/Internet'
import Tv from '../../pages/Tv'
import Phone from '../../pages/Phone'
import OptionalFeatures from '../../pages/OptionalFeatures'

import CtaButton from '../../components/Button/CtaButton'
import './Drawer.scss'

const { dismissDrawer } = CartCreators

const Drawer = ({
  history,
  isDrawerVisible,
  isChannelGridVisible,
  isDrawerInternetVisible,
  isDrawerTvVisible,
  isDrawerPhoneVisible,
  isDrawerOptionalsVisible,
  channelGridContent,
  dismissDrawerConnected
}) => (
  <div
    className={`drawer ${isDrawerVisible ? 'drawer--isVisible' : ''}`}
  >
    <Header />

    <div className='drawer__content'>
      <HeaderNavigation clickHandler={() => dismissDrawerConnected()} />

      {isChannelGridVisible && <ChannelGrid channels={channelGridContent} />}
      {isDrawerInternetVisible && <Internet />}
      {isDrawerTvVisible && <Tv />}
      {isDrawerPhoneVisible && <Phone />}
      {isDrawerOptionalsVisible && <OptionalFeatures />}

      {history.location.pathname === '/carrinho/' && (
        <CtaButton
          text='Continuar no carrinho'
          onClickHandler={() => dismissDrawerConnected()}
        />
      )}
    </div>
  </div>
)

Drawer.propTypes = {
  isDrawerVisible: PropTypes.bool,
  isDrawerInternetVisible: PropTypes.bool,
  isDrawerTvVisible: PropTypes.bool,
  isDrawerPhoneVisible: PropTypes.bool,
  isDrawerOptionalsVisible: PropTypes.bool,
  isChannelGridVisible: PropTypes.bool,
  dismissDrawerConnected: PropTypes.func,
  channelGridContent: PropTypes.array
}

Drawer.defaultProps = {
  isDrawerVisible: false,
  isDrawerInternetVisible: false,
  isDrawerTvVisible: false,
  isDrawerPhoneVisible: false,
  isDrawerOptionalsVisible: false,
  isChannelGridVisible: false,
  dismissDrawerConnected: () => {
  },
  channelGridContent: {}
}

const mapStateToProps = store => ({
  isDrawerVisible: store.app.isDrawerVisible,
  isChannelGridVisible: store.app.isChannelGridVisible,
  isDrawerInternetVisible: store.app.isDrawerInternetVisible,
  isDrawerTvVisible: store.app.isDrawerTvVisible,
  isDrawerPhoneVisible: store.app.isDrawerPhoneVisible,
  isDrawerOptionalsVisible: store.app.isDrawerOptionalsVisible,
  channelGridContent: store.app.channelGrid
})

const mapDispatchToProps = dispatch => bindActionCreators({
  dismissDrawerConnected: dismissDrawer
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)
